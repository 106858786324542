  <div mat-dialog-content>
    <h1 mat-dialog-title class="has-text-weight-medium is-size-3 mt-3 mb-3">Nowe zamówienie</h1>
    <div fxLayout="row" fxLayoutAlign="start center" class="mr-4">

      <span><strong class="has-text-weight-medium is-size-6">Nie można dodać produktów z kilku restauracji jednocześnie. Czy chcesz usunąć obecny koszyk?</strong></span>
    </div>




  </div>

  <div mat-dialog-actions>
    <button  type="submit" class="button is-primary submit" mat-button matDialogClose="yes">Usuń</button>
    <button mat-button matDialogClose="no" class="button is-danger cancel">Anuluj</button>
  </div>


