<main class="main">
  <article>
    <h1 class="title has-text-weight-semibold">Kontakt</h1>
    <div class="content p-5">
      <section class="content_section">
        <div class="content_info">
          <div class="align">
            <mat-icon class="is-size-5">info</mat-icon>
            <h2 class="m-0 has-text-weight-medium has-text-black is-size-5">
              Dane spółki
            </h2>
          </div>
        </div>
        <div class="content_details">
          <p>Speedy Dowozy Sp. z o.o.</p>
          <p>Os. Ogrody nr 3 lok. 49</p>
          <p>27-400 Ostrowiec Świętokrzyski</p>
          <hr />
          <p>NIP: 6612380959</p>
          <p>REGON: 387792969</p>
          <p>KRS: 0000875939</p>
        </div>
      </section>
      <section class="content_section">
        <div class="content_info">
          <div class="align">
            <mat-icon class="is-size-5">alternate_email</mat-icon>
            <h2 class="m-0 has-text-weight-medium has-text-black is-size-5">
              Kontakt
            </h2>
          </div>
        </div>
        <div class="content_details">
          <div class="mb-5">
            <p class="has-text-weight-medium mb-1">
              Reklamacje i kontakt ogólny
            </p>
            <a href="mailto:kontakt@speedyjedzonko.pl"
              >kontakt@speedyjedzonko.pl</a
            >
          </div>
          <div>
            <p class="has-text-weight-medium mb-1">
              Obsługa techniczna serwisu
            </p>
            <a href="mailto:support@speedyjedzonko.pl"
              >support@speedyjedzonko.pl</a
            >
          </div>
        </div>
      </section>
    </div>
  </article>
</main>
