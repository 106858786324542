<!-- <div fxLayoutAlign="center center"  fxLayout="column">
  <div fxLayout class="area-box" *ngFor="let area of areas" fxLayout="row wrap"  fxLayoutGap="10px">

    <div><a href="/areas/{{ area.Id }}/restaurants">{{ area.Name }}</a></div>
    <div style="width: 100%">
      <img src="/assets/krakow.jpg" style="object-fit: fill; width: 100%; height: 150px;">
      sfgsfg
    </div>
  </div>

</div> -->
<section class="hero">
  <div class="hero-body">
    <p class="title">
      Obszary
    </p>
    <p class="subtitle is-size-6">
      <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquet libero nec tellus vulputate dictum. -->
      <!-- TODO update description-->
    </p>
  </div>
</section>
<div fxLayoutAlign="flex-start center" fxLayout="row" class="areas">
  <a *ngFor="let area of areas" [routerLink]="['/areas/'+ area.Id + '/restaurants']" [state]="{area_name: area.Name}" class="area">
    <div class="card">
      <div class="card-image">
        <figure class="image">
          <img fetchpriority="high" rel="preload" as="image" [src]="area.Photo" alt="{{ area.Name }} image">
        </figure>
      </div>
      <div class="card-content p-3">
        <div class="media-content">
          <div class="content">
            <h2 class="is-size-6 mb-0">{{ area.Name }}</h2>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
