<a
  *ngIf="!isHidden && ($cart | async)?.TotalPrice"
  class="button is-primary is-size-6"
  [routerLink]="['/cart']"
>
  <mat-icon
    [matBadge]="($cartItems | async)?.length"
    matBadgeOverlap="true"
    class="is-size-5 mr-1"
    >shopping_cart</mat-icon
  >
  <strong class="is-size-6"
    ><span class="mr-2 ml-3">Koszyk</span>
    {{ ($cart | async)?.TotalPrice | number : "1.2-2" }} zł</strong
  >
</a>
