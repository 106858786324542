import { Component, OnInit } from '@angular/core';
import { DataService } from './services/data.service';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Speedy Jedzonko';

  restaurants = Array();

  constructor(private data: DataService) {}

  ngOnInit(): void {

    document.addEventListener('DOMContentLoaded', () => {
      (document.querySelectorAll('.notification .delete') || []).forEach($delete => {

        $delete.addEventListener('click', () => {
            document.getElementById('error-notification')?.classList.add('is-hidden');
        });
      });
    });

    if (Config.ForceHttps === 1) {
      if (location.protocol === 'http:') {
        window.location.replace(location.href.replace('http', 'https'));
      }
    }
  }



}
