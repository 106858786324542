import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Area } from '../models/area.model';


@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {

  areas: Area[] = [];

  constructor(
    private api: DataService,
  ) { }

  ngOnInit(): void {
    console.log("AreasComponent");
    this.getAreas();
  }


  getAreas() {
    this.api.getAreas().subscribe({
      next: (areas: Area[]) => {
        console.log(areas);
        this.areas = areas;
      },
      error: (err: Error) => {

      },
      complete: () => {

      }
    })
  }
}
