export class IArea {
  protected id: string = '';
  protected name: string = '';
  protected photo: string = '';

  get Id() {
    return this.id;
  }
  get Name() {
    return this.name;
  }
  get Photo(){
    return this.photo;
  }
}

export class Area extends IArea {
  constructor(builder: AreaBuilder) {
    super();
    this.id = builder.Id;
    this.name = builder.Name;
    this.photo = builder.Photo;
  }


}

export class AreaBuilder extends IArea {
  constructor(name: string) {
    super();
    this.name = name;
  }

  setId(id: string) {
    this.id = id;
    return this;
  }

  setName(name: string){
    this.name = name;
    return this;
  }

  setPhoto(photo: string){
    this.photo = photo;
    return this;
  }

  build(): Area {
    return new Area(this);
  }
}
