import { Component } from '@angular/core';

@Component({
  selector: '',
  template: `
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-cookies-banner></app-cookies-banner>
  `,
  styleUrls: [],
})
export class LoginLayout { }
