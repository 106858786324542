import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Account} from 'src/app/models/account.model';
import {DataService} from 'src/app/services/data.service';
import {StateService} from 'src/app/services/state.service';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.css'],
  encapsulation: ViewEncapsulation.None // for css
})
export class AccountLayoutComponent implements OnInit {

  email: string = '';
  name: string = '';
  account: Account = <Account>{};

  constructor(
    private api: DataService,
    private state: StateService,
  ) {

  }

  ngOnInit(): void {
    this.getAccount();

  }

  getAccount() {
    this.api.getAccount().subscribe({
      next: (account: Account) => {
        this.email = account.Email;
        this.name = `${account.Name} ${account.LastName}`;
        this.account = account;
        this.state.sendData(this.account);


        this.state.sendData(account);

        // console.log(account);
      },
      error: (err: Error) => {
        console.log(err);
        // TODO: display error
      },
      complete: () => {
        // TODO: close loader
      }
    })
  }

}
