<!--<div class="box" fxFlex="350" fxFlex.lg="400" fxFlex.xs="100" fxLayout="column">-->
<!--&lt;!&ndash; <mat-card> &ndash;&gt;-->
<!--  <div><a class="title is-5" href="/areas/{{ area.Id }}/restaurants/{{ restaurant.Id }}">{{restaurant.Name}}</a></div>-->
<!--  <div class="subtitle is-6"><ng-container *ngIf="restaurant.Categories?.length == 0">&nbsp;</ng-container><ng-container *ngFor="let category of categories; last as isLast">{{ category.category_name}}<ng-container *ngIf="!isLast"> · </ng-container></ng-container></div>-->

<!--  <mat-card-header>-->
<!--  </mat-card-header>-->
<!--  <mat-card-subtitle>{{restaurant.Address1}} {{restaurant.Address2}}<ng-container *ngIf="restaurant.Address3 != ''">/{{restaurant.Address3}}</ng-container>, {{restaurant.Postcode}} {{restaurant.City}}</mat-card-subtitle>-->

<!--</div>-->
<a [routerLink]="['/areas/' + area.Id + '/restaurants/' + restaurant.Id]" [title]="'Restauracja ' + restaurant.Name">
  <article class="box p-0" fxLayout="column">
    <img mat-card-image
         rel="preload"
         as="image"
         [src]="restaurant.Logo"
         alt="{{ restaurant.Name }} logo"
         width="645"
         height="180"
         >
    <section class="bottom p-3 pb-0">
      <div class="bottom__info"><h2 class="mb-0">{{ restaurant.Name }}</h2></div>
      <div>
        <div fxLayout="row" fxLayoutAlign="start center">{{restaurant.Address1}} {{restaurant.Address2}}
          <ng-container *ngIf="restaurant.Address3 != ''">/{{restaurant.Address3}}</ng-container>
          , {{restaurant.Postcode}} {{restaurant.City}}
          <!--          <span class="ml-2 mr-2">&bull;</span> <strong-->
          <!--            class="has-text-weight-medium">2 km</strong>-->
        </div>
        <div fxLayout="row" class="mt-2" fxLayoutAlign="start center" *ngIf="restaurant.DeliveryTime !== ''">
          <mat-icon class="is-size-5 mr-1">access_time</mat-icon>
          <span class="has-text-weight-medium">{{ restaurant.DeliveryTime }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center" class="mr-4">
            <mat-icon class="mr-1 is-size-5">shopping_basket</mat-icon>
            <span>Min. <strong class="has-text-weight-medium">{{ restaurant.MinOrderValue }}zł</strong></span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-1 is-size-5">directions_car</mat-icon>
            <span class="has-text-weight-medium">{{ restaurant.DeliveryRate }}zł</span>
          </div>
        </div>
        <div id="restaurant-status">
          <ng-container *ngIf="restaurant.DisabledOrders === true">
            <span style="color: #B30000;">Zamknięta</span>
          </ng-container>

          <ng-container *ngIf="restaurant.DisabledOrders === false">
            <span style="color: #006600;">Otwarta</span>
          </ng-container>


        </div>


      </div>

    </section>

  </article>
</a>
