<footer class="footer">
  <section class="footer_content">
    <ul>
      <li *ngFor="let link of FOOTER_LINKS">
        <a class="link" [routerLink]="link.url">{{ link.text }}</a>
      </li>
    </ul>
  </section>
  <hr />
  <div class="footer_brand">
    <p>
      Powered by <strong><a href="https://arkdevs.ee">ArkDevs</a></strong>
    </p>
  </div>
</footer>
