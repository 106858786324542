<ng-container *ngIf="!cookiesAccepted">
  <div class="outer"></div>
  <div class="banner box">
    <div class="mr-6">
      <h3 class="mb-1 is-size-6">Pliki cookie</h3>
      <p class="mb-0">
        Używamy niezbędnych plików cookie, które zapewniają prawidłowe
        funkcjonowanie serwisu i jego podstawowych funkcji.
      </p>
    </div>
    <button (click)="saveCookiesConsent()" class="button is-primary ml-auto">
      Zaakceptuj
    </button>
  </div>
</ng-container>
