export class IAddress {
  protected area_id: string = "";
  protected address1: string = "";
  protected address2: string = "";
  protected address3: string = "";
  protected city: string = "";
  protected postcode: string = "";
  protected phone: string = "";

  get AreaId() {
    return this.area_id;
  }

  get Address1() {
   return this.address1;
  }

  get Address2() {
    return this.address2;
   }

  get Address3() {
  return this.address3;
  }

  get City() {
    return this.city;
  }

  get Postcode() {
    return this.postcode;
  }

  get Phone() {
    return this.phone;
  }

  get Note() {
    return this.phone;
  }

}


export class Address extends IAddress{

  constructor(builder: AddressBuilder){
    super();
    this.area_id = builder.AreaId;
    this.address1 = builder.Address1;
    this.address2 = builder.Address2;
    this.address3 = builder.Address3;
    this.city = builder.City;
    this.postcode = builder.Postcode;
    this.phone = builder.Phone;
  }


  toJSON(): Object {
    return {
      "address1": this.address1,
      "address2": this.address2,
      "address3": this.address3,
      "city": this.city,
      "postcode": this.postcode,
      "phone": this.phone,
    }
  }

}

export class AddressBuilder extends IAddress {

  setAreaId(areaId: string) {
    this.area_id = areaId;
    return this;
  }

  setAddress1(address1: string) {
    this.address1 = address1;
    return this;
  }

  setAddress2(address2: string) {
    this.address2 = address2;
    return this;
  }

  setAddress3(address3: string) {
    this.address3 = address3;
    return this;
  }

  setCity(city: string) {
    this.city = city;
    return this;
  }

  setPostcode(postcode: string) {
    this.postcode = postcode;
    return this;
  }

  setPhone(phone: string) {
    this.phone = phone;
    return this;
  }

  build(): Address {
    return new Address(this);
  }
}
