import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginGroup: FormGroup;
  error = null;
  loading = false;

  constructor(
    private router: Router,
    private data: DataService,
  ) {
    this.loginGroup = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
      }
    );
  }

  ngOnInit(): void {

  }


  onSubmit() {
    this.error = null;
    console.warn(this.loginGroup.getRawValue());
    this.loading = true;

    this.data.login(this.email?.value, this.password?.value).subscribe({
      next: (resp: any) => {
        if (resp.status === 0) {
          this.error = resp;

        }

        if (resp.status === 1) {
          localStorage.setItem("token", resp.token);
          this.router.navigateByUrl('areas');
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.loading = false;
      }
    })


  }


  get email() { return this.loginGroup.get('email'); }
  get password() { return this.loginGroup.get('password'); }
}
