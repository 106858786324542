<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">

  <div class="content" style="width: 300px;">
    <form class="box" (ngSubmit)="onSubmit()" [formGroup]="loginGroup">
      <div id="logo">
        <a href="/"><img rel="preload" as="image" width="100" height="100" src="assets/logo3.svg" alt="speedyjedzonko - logo"></a>
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-right">
          <input class="input" type="email" formControlName="email" placeholder="twoj@email.com" email>
          <ng-container *ngIf="(email?.invalid && email?.touched) || (email?.dirty)">
            <p *ngIf="email?.errors?.email" class="help is-danger">Wprowadź prawidłowy e-mail</p>
            <p *ngIf="email?.errors?.required" class="help is-danger">Pole e-mail jest wymagane</p>
          </ng-container>


        </div>
      </div>

      <div class="field">
        <label class="label">Hasło</label>
        <div class="control">
          <input class="input" type="password" formControlName="password" placeholder="********">
          <p *ngIf="password?.errors?.required && (password?.touched || password?.dirty)" class="help is-danger">Pole hasło jest wymagane</p>
          <p layout-wrap *ngIf="error && (error!['status'] === 0)" class="help is-danger">{{ error!['message'] }}</p>
        </div>
      </div>



      <div fxLayout="column" fxLayoutGap="20px">
        <button fxFill [disabled]="!loginGroup.valid || loading" class="button is-primary" [class.spinner]="loading">Zaloguj</button>

        <div fxLayoutAlign="center center" id="password-recovery">
          <a href="/password-recovery">Przypomnienie hasła</a>
        </div>

        <div fxLayoutAlign="center center" id="register">
          <a href="/register">Załóż nowe konto</a>
        </div>

      </div>
    </form>
  </div>



</div>

