import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { Area, AreaBuilder } from '../models/area.model';
import { ActivatedRoute, Router } from '@angular/router';
import {take, finalize, isEmpty} from 'rxjs/operators';
import { Restaurant } from '../models/restaurant.model';

@Component({
  selector: 'app-list-restaurants',
  templateUrl: './list-restaurants.component.html',
  styleUrls: ['./list-restaurants.component.css']
})
export class ListRestaurantsComponent implements OnInit {


  area: Area = <Area>{};
  restaurants: Restaurant[] = [];
  isEmpty: boolean = false;
  area_name: string = '';


  constructor(
    private api: DataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.area_name = (this.router?.getCurrentNavigation()?.extras.state?.area_name || localStorage.getItem('area_name') || '');
    localStorage.setItem('area_name', this.area_name);

    this.route.params.pipe(
      take(1),
    ).subscribe((param) => {
      this.area = new AreaBuilder('').setId(param.areaId).build();
    });
   }

  ngOnInit(): void {
    this.getRestaurants();

    console.log(this.area_name);

  }



  getRestaurants() {
    this.api.getRestaurantsByArea(this.area).subscribe({
      next: (restaurants: Restaurant[]) => {
        this.restaurants = restaurants;
        console.log(this.restaurants);
        if(restaurants.length === 0)
          this.isEmpty = true;

      },
      error: (err: Error) => {

      },
      complete: () => {

      }
    })
  }
}
