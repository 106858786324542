<main class="container is-max-widescreen mt-6">
  <div class="columns">
    <div class="column is-three-fifths">
      <h1 class="has-text-weight-semibold mb-6">Zamówienie z {{ cart.RestaurantName }}</h1>
      <h3 class="has-text-weight-medium">Sposób dostawy</h3>
      <form [formGroup]="deliveryFormGroup" id="deliveryForm">
        <fieldset>
          <div class="box mb-5" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="icon_div">
              <i class="fas fa-car-side"></i>
            </div>
            <div>
              <label for="online" class="has-text-weight-medium">Dostawa do domu ({{ cart!!.DeliveryRate| number: '1.2-2' }} zł)</label>
            </div>
            <div class="change_section">
              <input type="radio" id="delivery" name="delivery_type" formControlName="delivery_type" value="1" checked (change)="setDeliveryType($event)">
            </div>
          </div>

          <div class="box mb-5" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="icon_div">
              <i class="fas fa-person-running"></i>
            </div>
            <div>
              <label for="online" class="has-text-weight-medium">Odbiór osobisty (0.00 zł)</label>
            </div>
            <div class="change_section">
              <input type="radio" id="delivery" name="delivery_type" formControlName="delivery_type" value="2" (change)="setDeliveryType($event)">
            </div>
          </div>
        </fieldset>
      </form>
      <ng-container *ngIf="isDelivery">
        <h3 class="has-text-weight-medium">Adres</h3>
        <div *ngIf="!isAddressFormOpened" class="box mb-5" fxLayout="row" fxLayoutAlign="flex-start center">
          <div class="icon_div">
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <ng-container *ngIf="address.Address1 === ''">
            <div class="loader"></div>
          </ng-container>
          <div>
            <b>{{ address.Address1 }} {{ address.Address2 }} <span *ngIf="address.Address3">/</span> {{ address.Address3 }}</b>
            <p class="mb-1 mt-1 has-text-grey">{{ address.City }} {{ address.Postcode }}</p>
            <p class="m-0 has-text-grey">{{ address.Phone }}</p>
          </div>
          <div class="change_section">
            <button class="btn button is-primary" (click)="openForm()">Zmień</button>
          </div>
        </div>
        <div *ngIf="isAddressFormOpened" class="box mb-5 address_form" fxLayout="row" fxLayoutAlign="flex-start center">
          <div class="icon_div">
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <div class="address_section">
            <form [formGroup]="addressFormGroup" id="addressForm">
              <div class="field">
                <label class="label is-size-7">Adres</label>
                <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
                  <input type="text" class="input address_input mr-1" placeholder="Ulica" formControlName="address1" name="address1">
                  <input type="text" class="input number_input" formControlName="address2" name="address2">
                  <p class="slash_p mb-0">/</p>
                  <input type="text" class="input number_input--wider" formControlName="address3" name="address3">
                </div>
                <p *ngIf="address1?.invalid && address1?.touched" class="help is-danger">Podaj adres</p>
                <p *ngIf="address2?.invalid && address2?.touched" class="help is-danger">Podaj numer bloku</p>
              </div>
              <div class="field">
                <label class="label is-size-7">Miasto i kod pocztowy</label>
                <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
                  <input type="text" class="input address_input mr-1" placeholder="Miasto" formControlName="city" name="city">
                  <input type="text" class="input postcode {{ postcode?.value.length === 0 ? 'empty' : ''}}" formControlName="postcode" name="postcode" mask="00-000" [showMaskTyped] = "true">
                </div>
                <p *ngIf="city?.invalid && city?.touched" class="help is-danger">Podaj miasto</p>
                <p *ngIf="postcode?.invalid && postcode?.touched" class="help is-danger">Podaj prawidłowy kod pocztowy</p>
              </div>
              <div class="field">
                <label class="label is-size-7">Nr telefonu</label>
                <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
                  <input type="tel" name="phone" id="phone" placeholder="Nr telefonu"
                         class="input phone_input" formControlName="phone">
                </div>
                <p *ngIf="phone?.invalid && phone?.touched" class="help is-danger">Podaj prawidłowy numer telefonu</p>
              </div>
            </form>
            <div class="mt-2">
              <button class="btn button mr-1" [class.is-light]="!addressFormGroup.valid" [class.is-primary]="addressFormGroup.valid" [disabled]="!addressFormGroup.valid" (click)="closeForm()">Zapisz</button>
              <button class="btn button is-danger" (click)="cancelForm()">Anuluj</button>
            </div>
          </div>
          <div class="change_section">


            <!-- <button *ngIf="addressFormGroup.invalid" class="btn has-text-grey" disabled>Zapisz</button> -->
          </div>
        </div>
      </ng-container>
      <h3 class="has-text-weight-medium">Metoda płatności</h3>
      <form [formGroup]="paymentFormGroup" id="paymentForm">
        <fieldset >
          <!-- <div class="box" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="icon_div">
              <i class="fas fa-credit-card"></i>
            </div>
            <div>
              <label for="online_stripe" class="has-text-weight-medium">Płatność online - Stripe ({{ order!!.PaymentFee | number: '1.2-2' }} zł)</label>
            </div>
            <div class="change_section">
              <input type="radio" id="online_stripe_global" name="payment_type" formControlName="payment_type" value="1" checked (change)="setPaymentType($event)">
            </div>
          </div> -->

          <div class="box" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="icon_div">
              <i class="fas fa-wallet"></i>
            </div>
            <div>
              <label for="cash" class="has-text-weight-medium">Płatność gotówką</label>
            </div>
            <div class="change_section">
              <input type="radio" id="cash" name="payment_type" formControlName="payment_type" value="2" checked (change)="setPaymentType($event)">
            </div>
          </div>

          <div class="box" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="icon_div">
              <i class="fas fa-credit-card"></i>
            </div>
            <div>
              <label for="online_hotpay_global" class="has-text-weight-medium">Płatność online - HotPay</label>
            </div>
            <div class="change_section">
              <input type="radio" id="online_hotpay_global" name="payment_type" formControlName="payment_type" value="3"  (change)="setPaymentType($event)">
            </div>
          </div>
        </fieldset>
      </form>

    </div>
    <div class="column">
      <div class="box">
        <h2>Koszyk</h2>
        <div class="basket_row" fxLayout="row" fxLayoutAlign="flex-start center" *ngFor="let cartItem of $cartItems | async; let i=index">
          <div class="icon_div has-text-grey">
            {{ cartItem.Quantity }}x
          </div>
          <div>
            <p class="has-text-weight-medium mb-0">{{ cartItem.Name }}</p>
            <div class="is-size-7">
              <p class="m-0 has-text-grey" *ngFor="let ingredient of cartItem.IngredientNames">{{ ingredient }}</p>
            </div>

          </div>
          <div class="change_section">
            {{ cartItem.TotalPrice | number: '1.2-2' }} zł
          </div>
        </div>
        <hr>
        <section class="has-text-grey price_section">
          <div>Opłata za dowóz: <span class="has-text-weight-medium">{{ order!!.DeliveryFee | number: '1.2-2' }}
            zł</span></div>
        </section>
        <!-- <hr>
        <section class="has-text-grey price_section">
          <div>Opłata za usługę płatności: <span class="has-text-weight-medium">{{ order!!.PaymentFee | number: '1.2-2' }}
            zł</span></div>
        </section> -->
        <hr>
        <section class="total_section">
          <strong>Razem</strong>
          <span class="has-text-weight-medium">
            {{ order!!.Total | number: '1.2-2' }} zł
          </span>
        </section>

      </div>
      <button class="button is-success submit" [class.spinner]="loading" [disabled]="loading" (click)="createOrder()">Zamawiam i płacę</button>
    </div>
  </div>

</main>
