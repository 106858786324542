import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { DialogService } from '../services/dialog.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericModalComponent } from '../components/generic-modal/generic-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  recoveryGroup: FormGroup;
  token: string = "";

  constructor(
    private data: DataService,
    private dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.pipe(
      take(1),
    ).subscribe((param) => {

      this.token = param.token;

    });

    this.recoveryGroup = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      }
    );

  }

  ngOnInit(): void {
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.data.resetPassword(this.password?.value, this.token).subscribe(
      {
        next: (resp: any) => {
          console.log(resp);
          if (resp.status == 1) {
            this.dialog.dialog.open(GenericModalComponent, {data:
              {message: resp.message, title: "Odzyskiwania hasła"}
            }).afterClosed().subscribe(res => {
              this.router.navigate(["/login"]);
            });

          } else {
            this.dialog.OpenErrorDialog({message: resp.message}).afterOpened().subscribe(res => {
              console.log(res);
              this.router.navigate(["/login"]);
            })
          }


        },
        error: err => {
          console.log(err);
          const notification = document.getElementById('error-notification');
          notification?.classList.remove('is-hidden');
        }
      }
    )
  }


  get password() { return this.recoveryGroup.get('password'); }

}
