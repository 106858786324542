import { Component, OnInit } from '@angular/core';
import { Area, AreaBuilder } from '../models/area.model';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  title = 'Speedy Jedzonko';

  restaurants = Array();
  isLogged = false;

  constructor(
    private data: DataService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("token") !== "") {
      this.isLogged = true;
    }

  }

}
