<div>

  <main class="panel" *ngIf="!isAddressFormOpened">
    <p class="panel-heading mb-0 is-pink">
      Adresy
    </p>

    <a class="panel-block" >
      <ng-container *ngIf="address.Address1 === undefined">
        <div class="loader"></div>
      </ng-container>


      <ng-container *ngIf="address.Address1">
        <p>{{ address.Address1 }} {{ address.Address2 }}
          <ng-container *ngIf="address.Address3 && address.Address3 !== ''">/{{ address.Address3 }}</ng-container>
          ,
          {{ address.City }} {{ address.Postcode }}
          <br>
          {{ address.Phone }}
        </p>
        <button class="button is-primary edit" (click)="openForm()">
          Edytuj
        </button>
      </ng-container>









    </a>
  </main>

  <div class="panel" *ngIf="isAddressFormOpened">
    <p class="panel-heading is-pink">
      Edycja adresu
    </p>
    <div class="panel-block">
      <form [formGroup]="addressFormGroup" id="addressForm" class="addressForm">
        <div class="field">
          <label class="label is-size-7">Adres</label>
          <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
            <input type="text" class="input address_input mr-1" placeholder="Ulica" formControlName="address1"
                   name="address1">
            <input type="text" class="input number_input" formControlName="address2" name="address2">
            <p class="slash_p mb-0">/</p>
            <input type="text" class="input number_input" formControlName="address3" name="address3">
          </div>
          <p *ngIf="address1?.invalid && address1?.touched" class="help is-danger">Podaj adres</p>
          <p *ngIf="address2?.invalid && address2?.touched" class="help is-danger">Podaj numer budynku</p>
        </div>
        <div class="field">
          <label class="label is-size-7">Miasto i kod pocztowy</label>
          <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
            <input type="text" class="input address_input mr-1" placeholder="Miasto" formControlName="city" name="city">
            <input type="text" [class.empty]="postcode?.value.length === 0" class="input postcode"
                   formControlName="postcode" name="postcode" mask="00-000" [showMaskTyped]="true">
          </div>
          <p *ngIf="city?.invalid && city?.touched" class="help is-danger">Podaj miasto</p>
          <p *ngIf="postcode?.invalid && postcode?.touched" class="help is-danger">Podaj prawidłowy kod pocztowy</p>
        </div>
        <div class="field">
          <label class="label is-size-7">Nr telefonu</label>
          <div class="control" fxLayout="row" fxLayoutAlign="flex-start center">
            <input type="tel" name="phone" id="phone" placeholder="Nr telefonu"
                   class="input phone_input" formControlName="phone">
          </div>
          <p *ngIf="phone?.invalid && phone?.touched" class="help is-danger">Podaj prawidłowy numer telefonu</p>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
          <button class="button is-primary" [disabled]="!addressFormGroup.valid || loading" [class.spinner]="loading" (click)="save()">Zapisz</button>
          <button class="button is-danger" (click)="cancel()">Anuluj</button>

        </div>
      </form>
    </div>
  </div>

</div>
