import { Config } from './../config';
// import { TokenInterceptor } from './token-interceptor';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { retry, catchError, finalize, tap, delay, retryWhen, take, concatMap, delayWhen } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  WhitelistedURLs = [
    "/logout",
    "/login",
    "/areas/",
    "/customers/account/password-recovery",
    "/customers/account/password-reset",
  ];

  constructor(
    private router: Router,
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('token') || '';
    if (this.WhitelistedURLs.includes(req.url.replace(Config.Host, "")) || req.url.replace(Config.Host, "").startsWith("/areas/"))
      return next.handle(req);

    if (token != '') {
      const reqWithToken = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(reqWithToken).pipe(
        retryWhen(delayWhen((err, index) => index < 1 ? timer(1000) : throwError(err))),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
        finalize(() => {
          // TODO profiling
          console.log("finalize");
        }),
        tap((event: any) =>  {
          if (event instanceof HttpResponse) {




            if (event.body?.status === 9) {
              console.log(event.body);
              this.router.navigate(['login']);

            }
          }
        }),
      );
    } else {
      this.router.navigate(['login']);
    }

    return next.handle(req);
  }
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    TokenInterceptor,
    { provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class TokenInterceptorModule {

  static forRoot(): ModuleWithProviders<TokenInterceptorModule> {
    return {
      ngModule: TokenInterceptorModule,
      providers: [
        { provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        }
      ]
    };
  }

}
