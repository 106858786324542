<aside class="menu">
  <article class="menu__account">
    <h1 class="mb-2 is-size-6 has-text-weight-bold">Moje konto</h1>
    <h2 class="account__name mb-0">{{ name }}</h2>
    <p class="account__email has-text-weight-medium">{{ email }}</p>
  </article>
  <ul class="menu-list">
    <li><a [routerLink]="['/account']"><mat-icon class="is-size-5 mr-2">home</mat-icon><span>Konto</span></a></li>
    <li><a [routerLink]="['/account/orders']"><mat-icon class="is-size-5 mr-2">access_time</mat-icon><span>Historia zamówień</span></a></li>
    <li><a [routerLink]="['/account/addresses']"><mat-icon class="is-size-5 mr-2">contact_mail</mat-icon><span>Adresy</span></a></li>
    <li><a [routerLink]="['/account/password']"><mat-icon class="is-size-5 mr-2">key</mat-icon><span>Zmień hasło</span></a></li>
    <li><a [routerLink]="['/logout']"><mat-icon class="is-size-5 mr-2">logout</mat-icon><span>Wyloguj się</span></a></li>
  </ul>
</aside>
