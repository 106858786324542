import { Component, OnInit } from '@angular/core';

const COOKIE_CONSENT = 'COOKIE_CONSENT';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.css'],
})
export class CookiesBannerComponent implements OnInit {
  constructor() {}

  cookiesAccepted = false;

  ngOnInit(): void {
    this.cookiesAccepted = localStorage.getItem(COOKIE_CONSENT) === 'true';
  }

  saveCookiesConsent() {
    this.cookiesAccepted = true;
    localStorage.setItem(COOKIE_CONSENT, 'true');
  }
}
