<div mat-dialog-content>
  <h1 mat-dialog-title class="has-text-weight-medium is-size-3 mt-3 mb-3">Wystąpił błąd</h1>
  <div fxLayout="row" fxLayoutAlign="start center" class="mr-4">

    <span><strong class="has-text-weight-medium is-size-6">{{ data.message }}</strong></span>
  </div>




</div>

<div mat-dialog-actions>
  <button  type="submit" class="button is-primary submit" matDialogClose="ok" mat-button>OK</button>
</div>
