<div style="border-top: 1px solid #000; margin-top: 30px; padding-top: 30px;">

  <div fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout="row wrap">
    <div *ngFor="let productGroup of productGroups" class="product-group-box">
      <p><a href="/areas/{{ restaurant.AreaId }}/restaurants/{{ restaurantId }}/product/groups/{{ productGroup.Id }}">{{ productGroup.Name }}</a></p>
      <div style="width: 100%">
        <img rel="preload" as="image" src="/assets/krakow.jpg" style="object-fit: cover; width: 100%; height: 150px;">
      </div>
    </div>
  </div>


</div>


<!-- <div fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout="column">
  <div *ngFor="let product of products" class="product-box">
    <p><a href="/restaurants/{{ product.RestaurantId }}/product/groups/{{ product.ProductGroupId }}/items/{{ product.Id }}">{{ product.Name }}</a></p>
    <div style="width: 100%">
      <img src="/assets/krakow.jpg" style="object-fit: cover; width: 100%; height: 150px;">
    </div>
  </div>



</div> -->
