<article
  *ngIf="product"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="product p-3"
>
  <section class="info" fxLayout="column" fxLayoutAlign="center space-between">
    <h2 class="mb-0 product_name">{{ product.Name }}</h2>
    <p class="description">{{ product.Description }}</p>
    <div fxLayout="row" fxLayoutAlign="start center" class="mr-4 product_price">
      <mat-icon class="mr-1 is-size-5">shopping_basket</mat-icon>
      <span
        ><strong class="has-text-weight-medium"
          >{{ product.Price | number : "1.2-2" }}zł</strong
        ></span
      >
    </div>
  </section>
  <section class="photo">
    <img rel="preload" as="image" [src]="product.Photo" alt="{{ product.Name }} image" class="img" />
  </section>
</article>
