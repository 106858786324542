import { Component } from '@angular/core';

@Component({
  selector: '',
  template: `
    <app-navbar [isLogged]="this.isLogged"></app-navbar>
    <router-outlet></router-outlet>
    <app-checkout-button></app-checkout-button>
    <app-footer></app-footer>
    <app-cookies-banner></app-cookies-banner>
  `,
  styleUrls: ['./home.layout.css'],
})
export class HomeLayout {
  isLogged = localStorage.getItem('token') !== '';
}
