<div class="content">
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxFlexAlign.lt-sm="center center"
    class="mt-4"
  >
    <div id="restaurant-logo">
      <img
        style="border-radius: 1%"
        [src]="restaurant.Logo"
        alt="{{ restaurant.Name }} logo"
        class="image mr-4"
      />
    </div>

    <div id="restaurant-info" class="restaurant-info">
      <div fxLayout="column">
        <div id="title" class="title">{{ restaurant.Name }}</div>
        <div id="address" class="subtitle is-size-6">
          <span class="icon">
            <i class="mdi mdi-map-marker"></i>
          </span>
          {{ restaurant.Postcode }} {{ restaurant.City }},
          {{ restaurant.Address1 }} {{ restaurant.Address2 }}
          <ng-container *ngIf="restaurant.Address3">/{{ restaurant.Address3 }}</ng-container>
        </div>
        <div id="categories" class="subtitle categories">
          <ng-container *ngFor="let tag of restaurant.Tags; let last = last">
            <span [routerLink]="[]" class="restaurant_category tag m-1">{{
              tag
            }}</span>
          </ng-container>
        </div>
        <ng-container *ngIf="restaurant.OpeningHoursDescription && restaurant.OpeningHoursDescription.length > 0">
          <label for="opening-hours" style="font-weight: bold;">Godziny otwarcia:</label>
          <div id="opening-hours">
            <ng-container *ngFor="let entry of restaurant.OpeningHoursDescription">
              <span>{{entry.label}}<br>{{entry.from}}<br>{{entry.to}}</span>
            </ng-container>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
  <p class="subtitle is-size-6 mt-5">
    <a [routerLink]="['../']">
      <i class="fas fa-angle-left mr-2"></i>
      Zobacz inne restauracje
    </a>
  </p>

  <!-- <div style="border-top: 1px solid #000; margin-top: 30px; padding-top: 30px;">

    <div fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout="row wrap">
      <div *ngFor="let productGroup of productGroups" class="product-group-box">
        <p><a href="/areas/{{ restaurant.AreaId }}/restaurants/{{ restaurantId }}/product/groups/{{ productGroup.Id }}">{{ productGroup.Name }}</a></p>
        <div style="width: 100%">
          <img src="/assets/krakow.jpg" style="object-fit: cover; width: 100%; height: 150px;">
        </div>
      </div>
    </div>


  </div> -->

  <!--  <router-outlet></router-outlet>-->

  <!-- <app-product-group></app-product-group> -->
  <main class="products mt-6">
    <div *ngFor="let categories of categoryItems" class="mb-4">
      <ng-container *ngIf="categories.items.length > 0">
        <h2 class="is-size-4 has-text-weight-medium">
          {{ categories.categoryName }}
        </h2>
        <section fxLayout="row wrap">
          <ng-container *ngFor="let item of categories.items">
            <app-product-tile
              class="product-modal-trigger"
              data-target="product-modal"
              (click)="addProduct(item)"
              [product]="item"
              *ngIf="item.Active"
            ></app-product-tile>
          </ng-container>
        </section>
      </ng-container>
    </div>
    <div *ngIf="isEmpty && categoriesToLoad == 0" class="empty">
      <i class="fas fa-utensils"></i>
      <h1 class="m-1">Wybrana restauracje nie dodała jeszcze żadnych dań.</h1>
      <a [routerLink]="['../']">Zobacz inne restauracje w wybranym obszarze</a>
    </div>
  </main>
</div>
