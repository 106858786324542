import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Cart, CartItem } from 'src/app/models/cart.model';
import { StateService } from 'src/app/services/state.service';

const IGNORED_PATHS = ['/summary', '/cart'];

@Component({
  selector: 'app-checkout-button',
  templateUrl: './checkout-button.component.html',
  styleUrls: ['./checkout-button.component.css'],
})
export class CheckoutButtonComponent implements OnInit {
  $cartItems: Observable<CartItem[]> = of();
  $cart: Observable<Cart> = of();

  isHidden = false;

  constructor(private router: Router, private state: StateService) {
    this.$cartItems = this.state.getCartItems();
    this.$cart = this.state.getCart();
  }
  ngOnInit(): void {
    this.isHidden = this.checkIgnoredPaths();
    this.router.events.subscribe(() => {
      this.isHidden = this.checkIgnoredPaths();
    });
  }

  checkIgnoredPaths() {
    for (let i = 0; i < IGNORED_PATHS.length; i++) {
      if (this.router.url.includes(IGNORED_PATHS[i])) {
        return true;
      }
    }

    return false;
  }
}
