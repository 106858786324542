<main class="main">
  <article>
    <h1 class="title has-text-weight-semibold">Regulamin</h1>
    <div class="content p-5">
      <section class="content_section">
        <p class="mb-0">
          <a
            href="https://docs.speedyjedzonko.pl/Regulamin_speedyjedzonko.pl_31.05.2023.pdf"
            >Regulamin obowiązuje od 31.05.2023r.</a
          >
        </p>
      </section>
    </div>
  </article>
</main>
