<div fxLayout="row" fxLayoutAlign="center center">

  <div class="content" style="width: 500px;">
    <form class="box" (ngSubmit)="onSubmit()" [formGroup]="loginGroup">
      <div class="field">
        <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px">

          <div fxLalout="column">
            <label class="label">Imię</label>
            <div class="control">
                <input class="input" type="text" formControlName="name" placeholder="Imię">
                <p *ngIf="name?.errors?.required && (name?.touched || name?.dirty)" class="help is-danger">Wprowadź imię</p>
            </div>
          </div>

          <div fxLayout="column">
            <label class="label">Nazwisko</label>
            <div class="control">
              <input class="input" type="text" formControlName="last_name" placeholder="Nazwisko">
              <p *ngIf="last_name?.errors?.required && (last_name?.dirty || last_name?.touched)" class="help is-danger">Wprowadź nazwisko</p>
            </div>
          </div>

        </div>
      </div>


      <div class="field" >
        <div fxLayout="row" fxLayoutGap="5px">

          <div fxLayout="column" fxFlex="60">
            <label class="label">Ulica</label>
            <div class="control">
              <input class="input" type="text" formControlName="address1" placeholder="Ulica">
              <p *ngIf="address1?.errors?.required && (address1?.dirty || address1?.touched)" class="help is-danger">Wprowadź nazwę ulicy</p>
            </div>
          </div>

          <div fxLayout="column" fxFlex="20">
            <label class="label">Nr domu</label>
            <div class="control">
              <input class="input" type="text" formControlName="address2" placeholder="Nr domu">
              <p *ngIf="address2?.errors?.required && (address2?.dirty || address2?.touched)" class="help is-danger">Wprowadź numer budynku</p>
            </div>
          </div>

          <div fxLayout="column" fxFlex="20">
            <label class="label">Nr lokalu</label>
            <div class="control">
              <input class="input" type="text" formControlName="address3" placeholder="Nr lokalu">
            </div>
          </div>

        </div>
      </div>

      <div class="field">
        <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px">

          <div fxLayout="column">
            <label class="label">Kod pocztowy</label>
            <div class="control">
              <input class="input" type="text" formControlName="postcode" placeholder="Kod pocztowy">
              <p *ngIf="postcode?.errors?.required && (postcode?.dirty || postcode?.touched)" class="help is-danger">Wprowadź kod pocztowy</p>
            </div>
          </div>

          <div fxLayout="column">
            <label class="label">Miejscowość</label>
            <div class="control">
              <input class="input" type="text" formControlName="city" placeholder="Miejscowość">
              <p *ngIf="city?.errors?.required && (city?.dirty || city?.touched)" class="help is-danger">Wprowadź nazwę miejscowości</p>
            </div>
          </div>

        </div>
      </div>

      <div class="field">
        <label class="label">Telefon</label>
        <div class="control has-icons-right">
          <input class="input" type="phone" formControlName="phone" placeholder="+48 501 234 567" >
        </div>
        <p *ngIf="phone?.errors?.required && (phone?.touched || phone?.dirty)" class="help is-danger">Wprowadź numer telefonu</p>
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-right">
          <input class="input" type="email" formControlName="email" placeholder="twoj@email.com" >
        </div>
        <p *ngIf="email?.errors?.required && (email?.touched || email?.dirty)" class="help is-danger">Wprowadź e-mail</p>
      </div>

      <div class="field">
        <label class="label">Hasło</label>
        <div class="control">
          <input class="input" type="password" formControlName="password" placeholder="********">
          <p *ngIf="password?.errors?.required && (password?.touched || password?.dirty)" class="help is-danger">Wprowadź hasło</p>
        </div>
      </div>

      <div class="field">
        <label class="label">Potwórz hasło</label>
        <div class="control">
          <input class="input" type="password" formControlName="repeat_password" placeholder="********">
          <p *ngIf="repeat_password?.errors?.required && (repeat_password?.touched || repeat_password?.dirty)" class="help is-danger">Potwórz hasło</p>
          <p *ngIf="(repeat_password?.touched || repeat_password?.dirty) && password?.value !== repeat_password?.value && repeat_password?.value !==''" class="help is-danger">Wprowadzone hasła muszą być identyczne</p>
        </div>
      </div>

      <div class="field">
        <button [disabled]="loginGroup.pristine || !loginGroup.valid || password?.value !== repeat_password?.value" class="button is-primary" fxFill>Utwórz konto</button>
      </div>

    </form>



  </div>
</div>

