import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProductGroup, ProductGroupBuilder } from '../models/product_group.model';
import { DataService } from '../services/data.service';
import { take, finalize } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { Restaurant, RestaurantBuilder } from '../models/restaurant.model';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  products: Product[] = [];
  productGroup: ProductGroup;
  restaurant: Restaurant;
  restaurantId: string = '';
  areaId: string = '';
  productGroupId: string = '';

  constructor(
    private api: DataService,
    private route: ActivatedRoute,
  ) {

    this.route.parent?.params.pipe(
      take(1),
    ).subscribe((param) => {
      console.log(param);

      this.restaurantId = param.restaurantId;
      this.areaId = param.areaId;



    });

    this.route.params.pipe(
      take(1),
    ).subscribe((param) => {
      console.log(param);

      this.productGroupId = param.productGroupId;



    });

    this.productGroup = new ProductGroupBuilder('')
        .setRestaurantId(this.restaurantId)
        .setId(this.productGroupId)
        .build();

        this.restaurant = new RestaurantBuilder('')
        .setId(this.restaurantId)
        .setAreaId(this.areaId)
        .build();

  }

  ngOnInit(): void {
    console.log("ProductComponent")
    this.getProducts();
  }

  getProducts() {
    this.api.getProducts(this.productGroup).subscribe({
      next: (products: Product[]) => {
        this.products = products;
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => {

      }
    })
  }

}
