import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-account-password',
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.css']
})
export class AccountPasswordComponent implements OnInit {

  loading = false;
  constructor(
    private api: DataService,
    private dialog: DialogService,
  ) { }

  ngOnInit(): void {
  }

  changePassword(password: any) {
    this.loading = true;
    this.api.changePassword(password).subscribe({
      next: (data: any) => {
        if (data?.status === 1) {
          // TODO: display successful info

        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);

        this.dialog.OpenErrorDialog({message: (err.error?.message || err?.message)});
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

}
