export class IAccount {
  protected id: string = '';
  protected name: string = '';
  protected last_name: string = '';
  protected email: string = '';
  protected address1: string = '';
  protected address2: string = '';
  protected address3: string = '';
  protected city: string = '';
  protected postcode: string = '';
  protected phone: string = '';

  get Id() {
    return this.id;
  }
  get Name() {
    return this.name;
  }

  get LastName() {
    return this.last_name;
  }

  get Email() {
    return this.email;
  }

  get Address1() {
    return this.address1;
  }

  get Address2() {
    return this.address2;
  }

  get Address3() {
    return this.address3;
  }

  get City() {
    return this.city;
  }

  get Postcode() {
    return this.postcode;
  }

  get Phone() {
    return this.phone;
  }

}


export class Account extends IAccount {

  constructor(builder: AccountBuilder) {
    super();
    this.id = builder.Id;
    this.name = builder.Name;
    this.last_name = builder.LastName;
    this.email = builder.Email;
    this.address1 = builder.Address1;
    this.address2 = builder.Address2;
    this.address3 = builder.Address3;
    this.city = builder.City;
    this.postcode = builder.Postcode;
    this.phone = builder.Phone;
  }

  toJSON(): Object {
    // TODO:
    return {
      name: this.Name,
      last_name: this.LastName,
      email: this.Email,

    }
  }


}

export class AccountBuilder extends IAccount {

  constructor() {
    super();
  }

  setId(id: string) {
    this.id = id;
    return this;
  }

  setName(name: string) {
    this.name = name;
    return this;
  }

  setLastName(lastName: string) {
    this.last_name = lastName;
    return this;
  }

  setEmail(email: string) {
    this.email = email;
    return this;
  }

  setAddress1(address1: string) {
    this.address1 = address1;
    return this;
  }

  setAddress2(address2: string) {
    this.address2 = address2;
    return this;
  }

  setAddress3(address3: string) {
    this.address3 = address3;
    return this;
  }

  setCity(city: string) {
    this.city = city;
    return this;
  }

  setPostcode(postcode: string) {
    this.postcode = postcode;
    return this;
  }

  setPhone(phone: string) {
    this.phone = phone;
    return this;
  }

  build(): Account {
    return new Account(this);
  }
}
