export class OrderStatus {
  public static WAITING_FOR_PAYMENT = 0;
  public static PENDING = 1;
  public static CONFIRMED = 2;
  public static IN_PROGRESS = 3;
  public static READY_FOR_DELIVERY = 4;
  public static IN_DELIVERY = 5;
  public static DELIVERED = 6;
  public static CANCELLED = 7;

  public static getStatusName(status_code: number){
    let name: string = 'Nieznany';
    switch (status_code) {
      case this.WAITING_FOR_PAYMENT: name = 'Oczekuje na płatność'; break
      case this.PENDING: name = 'Oczekuje na akceptację'; break
      case this.CONFIRMED: name = 'Potwierdzone'; break
      case this.IN_PROGRESS: name = 'W trakcie przygotowania'; break
      case this.READY_FOR_DELIVERY: name = 'Gotowe do dostarczenia'; break
      case this.IN_DELIVERY: name = 'W drodze'; break
      case this.DELIVERED: name = 'Dostarczone'; break
      case this.CANCELLED: name = 'Anulowane'; break
    }
    return name;

  }
}
