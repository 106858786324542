import { DialogService } from './../../services/dialog.service';
import { DataService } from 'src/app/services/data.service';
import { AccountBuilder } from './../../models/account.model';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Account } from 'src/app/models/account.model';
import { StateService } from 'src/app/services/state.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Address, AddressBuilder} from "../../models/address.model";
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-account-addresses',
  templateUrl: './account-addresses.component.html',
  styleUrls: ['./account-addresses.component.css']
})
export class AccountAddressesComponent implements OnInit {

  isAddressFormOpened = false;
  loading = false;



  addressFormGroup = new FormGroup({
    address1: new FormControl("", [
      Validators.required,
    ]),
    address2: new FormControl("", [
      Validators.required
    ]),
    address3: new FormControl("", []),
    city: new FormControl("", [
      Validators.required
    ]),
    postcode: new FormControl("",
      [
        Validators.required,
        Validators.pattern("^\\d{5}$")
      ]
    ),
    phone: new FormControl("",
      [
        Validators.required
      ]
    )
  })

  address: Address = new AddressBuilder().build();
  account: Account = new AccountBuilder().build();

  constructor(
    private route: ActivatedRoute,
    private state: StateService,
    private api: DataService,
    private dialog: DialogService,
  ) {
    this.state.getData().subscribe(data => {
      this.account = data;

      this.address = new AddressBuilder()
        .setAddress1(data?.address1)
        .setAddress2(data?.address2)
        .setAddress3(data?.address3)
        .setCity(data?.city)
        .setPostcode(data?.postcode)
        .setPhone(data?.phone)
        .build();

    });
  }

  ngOnInit(): void {


  }

  save(){
    this.loading = true;
    let postcode = this.postcode?.value.slice(0, 2) + "-" + this.postcode?.value.slice(2, 5);

    let address = new AddressBuilder()
      .setAddress1(this.address1?.value)
      .setAddress2(this.address2?.value)
      .setAddress3(this.address3?.value)
      .setCity(this.city?.value)
      .setPostcode(postcode)
      .setPhone(this.phone?.value)
      .build();

    this.address = address;
    this.account = new AccountBuilder()
      .setName(this.account.Name)
      .setLastName(this.account.LastName)
      .setEmail(this.account.Email)
      .setAddress1(this.address1?.value)
      .setAddress2(this.address2?.value)
      .setAddress3(this.address3?.value)
      .setCity(this.city?.value)
      .setPostcode(postcode)
      .setPhone(this.phone?.value)
      .build();

    console.log("account2=", this.account);

    this.api.changeAddress(address).subscribe({
      next: (data: any) => {
        if (data?.status === 1) {
          this.state.sendData(this.account);
          this.isAddressFormOpened = false;
        } else {
          this.dialog.OpenErrorDialog({message: data?.message});
        }
      },
      error: (err: Error) => {
        this.loading = false;
        this.dialog.OpenErrorDialog({message: err.message});
      },
      complete: () => {
        this.loading = false;
      }
    });


  }

  cancel() {
    this.isAddressFormOpened = false;
  }

  openForm(){
    this.postcode?.setValue(this.address.Postcode.replace('-', ''));
    this.address1?.setValue(this.address.Address1);
    this.address2?.setValue(this.address.Address2);
    this.address3?.setValue(this.address.Address3);
    this.phone?.setValue(this.address.Phone);
    this.city?.setValue(this.address.City);


    this.isAddressFormOpened = true;
  }

  get address1() {
    return this.addressFormGroup.get('address1');
  }

  get address2() {
    return this.addressFormGroup.get('address2');
  }

  get address3() {
    return this.addressFormGroup.get('address3');
  }

  get city() {
    return this.addressFormGroup.get('city');
  }

  get postcode() {
    return this.addressFormGroup.get('postcode');
  }

  get phone() {
    return this.addressFormGroup.get('phone');
  }

}
