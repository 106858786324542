import { Cart, CartBuilder, CartItemBuilder } from 'src/app/models/cart.model';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { Restaurant, RestaurantBuilder } from '../models/restaurant.model';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { ProductGroup } from '../models/product_group.model';
import { Product } from '../models/product.model';
import { DialogService } from './../services/dialog.service';
import {
  IngredientGroup,
  IngredientGroupBuilder,
} from '../models/ingredient_group.model';
import { StateService } from 'src/app/services/state.service';
import { Title } from '@angular/platform-browser';

interface CategoryItems {
  categoryName: string;
  items: Product[];
  position: string;
}

interface Ingredient {
  id: string;
  price: number;
  groupPrice: number;
  name: string;
  selected: boolean;
}

interface SingleSelectionIngredientGroup {
  id: string;
  price: number;
  ingredients: { [key: string]: { id: string; price: number } };
}

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css'],
})
export class RestaurantComponent implements OnInit {
  restaurant: Restaurant = <Restaurant>{};
  productGroups: ProductGroup[] = [];
  areaId: string = '';
  restaurantId: string = '';
  categoryItems: CategoryItems[] = [];
  ingredientGroups: { [key: string]: IngredientGroup } = {};
  isEmpty: boolean = true;
  categoriesToLoad: number = -1; // variable to track how many categories are left to load to determine when all content is loaded
  productModal: any;

  constructor(
    private api: DataService,
    private route: ActivatedRoute,
    public dialog: DialogService,
    private fb: FormBuilder,
    private state: StateService,
    private readonly title: Title
  ) {
    this.route.params.pipe(take(1)).subscribe((param: any) => {
      this.areaId = param.areaId;
      this.restaurantId = param.restaurantId;
      this.restaurant = new RestaurantBuilder('')
        .setId(this.restaurantId)
        .setAreaId(this.areaId)
        .build();
    });
  }

  ngOnInit(): void {
    this.getRestaurant();
    this.getProductGroups();
    this.getIngredientGroups();
  }

  // menu
  getProductGroups() {
    this.api.getProductGroups(this.restaurant).subscribe({
      next: (productGroups: ProductGroup[]) => {
        this.productGroups = productGroups;
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => {
        this.getProducts();
        this.categoriesToLoad = this.productGroups.length;
      },
    });
  }

  getRestaurant() {
    this.api.getRestaurant(this.restaurant).subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;

        this.title.setTitle(`${this.restaurant.Name} - ${this.restaurant.City}`);

        console.log(this.restaurant);
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => { },
    });
  }

  getProducts() {
    this.productGroups.forEach((group: ProductGroup) => {
      if (!group.Active) {
        return;
      }

      this.api.getProducts(group).subscribe({
        next: (items: Product[]) => {
          if (items.length > 0) {
            this.isEmpty = false;
          }

          this.categoryItems.push({
            categoryName: group.Name,
            position: group.Position,
            items: items,
          });
        },
        error: (err: Error) => {
          console.log(err);
        },
        complete: () => {
          this.categoriesToLoad--;
          this.categoryItems.sort((a,b) => { return a.position.localeCompare(b.position); });
        },
      });
    });
  }

  getIngredientGroups() {
    this.api.getIngredientsGroups(this.restaurant).subscribe({
      next: (ingredientGroups: IngredientGroup[]) => {
        ingredientGroups.forEach((ingredientGroup: IngredientGroup) => {
          this.ingredientGroups[ingredientGroup.Id] = ingredientGroup;
        });
      },
      error: (err: Error) => {
        console.log(err);
        // TODO: display error in GUI
      },
      complete: () => {
        // TODO: turn off loader
      },
    });
  }

  addProduct(product: Product) {
    let ingredientGroups: any[] = [];
    let multipleSelectionIngredientsList: any[] = [];
    let singleSelectionIngredientsList: any[] = [];

    let formGroup = new FormGroup({
      id: new FormControl(product.Id, []),
      name: new FormControl(product.Name, []),
      description: new FormControl(product.Description, []),
      quantity: new FormControl(1, []),
      price: new FormControl(product.Price),
      ingredient_groups: new FormArray([]),
      restaurant_id: new FormControl(product.RestaurantId, []),
    });

    let ingredientGroupsFormArray: FormArray = formGroup.controls[
      'ingredient_groups'
    ] as FormArray;
    product.IngredientGroups.forEach((ingredientGroupId: string) => {
      let ingredientGroup: IngredientGroup =
        this.ingredientGroups[ingredientGroupId];
      let ingredientGroupFormGroup = new FormGroup({
        id: new FormControl(ingredientGroup.Id),
        name: new FormControl(ingredientGroup.Name),
        choice_type: new FormControl(ingredientGroup.ChoiceType),
        price: new FormControl(ingredientGroup.Price),
        description: new FormControl(ingredientGroup.Description),
        ingredients: new FormArray([]),
        value: new FormControl(''),
      });

      let ingredientGroupIds: string[] = Object.keys(
        ingredientGroup.Ingredients
      );

      let ingredientsFormArray: FormArray = ingredientGroupFormGroup.controls[
        'ingredients'
      ] as FormArray;
      ingredientGroupIds.forEach((ingredientId: any) => {
        let ingredient: Ingredient = ingredientGroup.Ingredients[ingredientId];
        let ingredientFormGroup = new FormGroup({
          id: new FormControl(ingredient.id),
          price: new FormControl(ingredient.price),
          name: new FormControl(ingredient.name),
          selected: new FormControl(ingredient.selected),
        });

        if (ingredientGroup.ChoiceType === 'singleSelection') {
          ingredientGroupFormGroup.setControl(
            'value',
            new FormControl('', [Validators.required])
          );
        }

        ingredientsFormArray.push(ingredientFormGroup);
      });

      ingredientGroupsFormArray.push(ingredientGroupFormGroup);
    });

    let cart = new CartBuilder().load(localStorage.getItem('cart') || null);

    if (
      cart.RestaurantId !== product.RestaurantId &&
      cart.RestaurantId !== ''
    ) {
      this.dialog
        .OpenResetBasket()
        .afterClosed()
        .subscribe((deleted) => {
          if (deleted === 'yes') {
            localStorage.setItem('cart', '');
            localStorage.setItem('order', '');
            cart = new CartBuilder().load(localStorage.getItem('cart') || null);
            this.productModal = this.dialog
              .OpenAddProduct({
                form: formGroup,
                image: product.Photo,
                name: product.Name,
              })
              .afterClosed()
              .subscribe((added) =>
                this.AddProductCallback(added, product, cart)
              );
          }
        });
      return;
    }

    this.dialog
      .OpenAddProduct({
        form: formGroup,
        image: product.Photo,
        name: product.Name,
      })
      .afterClosed()
      .subscribe((added) => this.AddProductCallback(added, product, cart));
  }

  AddProductCallback(added: any, product: Product, cart: Cart) {
    if (added !== 'no') {
      console.log(added);
      console.log('added product to cart');
      console.log(added.form);

      cart.addItem(
        new CartItemBuilder()
          .setId(added.form.get('id').value)
          .setName(added.form.get('name').value)
          .setPrice(added.form.get('price').value)
          .setQuantity(added.form.get('quantity').value)
          .setIngredientGroups(
            added.form
              .get('ingredient_groups')
              .value.map((ingredientGroupFormGroup: any) => {
                return new IngredientGroupBuilder(ingredientGroupFormGroup.name)
                  .setId(ingredientGroupFormGroup.id)
                  .setPrice(ingredientGroupFormGroup.price)
                  .setDescription(ingredientGroupFormGroup.description)
                  .setType(ingredientGroupFormGroup.choice_type)
                  .setIngredients(
                    ingredientGroupFormGroup.ingredients.map(
                      (ingredientFormGroup: any) => {
                        return {
                          name: ingredientFormGroup.name,
                          id: ingredientFormGroup.id,
                          price: ingredientFormGroup.price,
                          selected: ingredientFormGroup.selected,
                        };
                      }
                    )
                  )
                  .build();
              })
          )
          .build()
      );

      cart.setRestaurantId(product.RestaurantId);
      cart.setRestaurantName(this.restaurant.Name);
      cart.setDeliveryRate(this.restaurant.DeliveryRate);
      localStorage.setItem('cart', JSON.stringify(cart.toJSON()));

      this.state.updateCart(cart);
      this.state.updateCartItems(cart.CartItems);

      console.log('cart', cart);
    }
  }
}
