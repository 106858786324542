import { Component, OnInit, Input } from '@angular/core';
import { Area } from 'src/app/models/area.model';
import { Restaurant } from 'src/app/models/restaurant.model';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})
export class TileComponent implements OnInit {

  @Input() area: Area;
  @Input() restaurant: Restaurant;
  categories: any;

  constructor(

  ){

    if ("restaurant" in this)
      this.categories =  this.restaurant.Categories;
  }

  ngOnInit(): void {
  }

}
