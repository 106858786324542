import { ErrorModalComponent } from './../components/error-modal/error-modal.component';
import { ProductModalComponent } from 'src/app/components/product-modal/product-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ResetBasketModalComponent } from 'src/app/components/reset-basket-modal/reset-basket-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog
  ) { }

  OpenAddProduct(data?: any) {
    return this.dialog.open(ProductModalComponent, {
      // disableClose: true,
      data: data,

    })
  }

  OpenResetBasket(data?: any) {
    return this.dialog.open(ResetBasketModalComponent, {
      data: data,
    })
  }

  OpenErrorDialog(data?: any) {
    return this.dialog.open(ErrorModalComponent, {
      data: data,
    })
  }
}
