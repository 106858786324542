import { IngredientGroupBuilder } from './../models/ingredient_group.model';
import { Component, OnInit } from '@angular/core';
import { Cart, CartBuilder, CartItem, CartItemBuilder } from 'src/app/models/cart.model';
import { IngredientGroup } from '../models/ingredient_group.model';
import { Observable, of } from 'rxjs';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cart: Cart = <Cart>{};
  $cartItems: Observable<CartItem[]> = of();
  $cart: Observable<Cart> = of();


  constructor(
    private state: StateService,
  ) {
    let json = localStorage.getItem("cart") || null;
    this.cart = new CartBuilder().load(json);


    this.state.updateCart(this.cart);
    this.state.updateCartItems(this.cart.CartItems);


   }

  ngOnInit(): void {
    this.$cartItems = this.state.getCartItems();
    this.$cart = this.state.getCart();

  }

  changeQuantity(item: CartItem, value: number) {
    let quantity = item.Quantity + value;
    if (quantity < 1) {
      this.cart.removeItem(item);


    } else {
      item.setQuantity(quantity);

    }

    if (this.cart.CartItems.length === 0) {
      localStorage.setItem("order", "");
      localStorage.setItem("cart", "");
      this.cart = new CartBuilder().build();
    }

    localStorage.setItem("cart", JSON.stringify(this.cart.toJSON()));

    this.state.updateCart(this.cart);
    this.state.updateCartItems(this.cart.CartItems);

  }

}
