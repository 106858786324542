export class IRestaurant {
  protected active: boolean;
  protected id: string;
  protected name: string;
  protected description: string;
  protected logo: string;
  protected phone: string;
  protected email: string;
  protected businessGroupId: string;
  protected businessGroupName: string;
  protected areaId: string;
  protected areaName: string;
  protected address1: string;
  protected address2: string;
  protected address3: string;
  protected city: string;
  protected postcode: string;
  protected latitude: string;
  protected longitude: string;
  protected categories: object[];
  protected foodsContractNumber: string;
  protected foodsMonthlyFee: number;
  protected foodsCommissionPercent: number;
  protected shipping: number; // 1, 2 or 3 (pickup, delivery, both)
  protected deliveryRate: number;
  protected minOrderValue: number;
  protected deliveryTime: string;
  protected openingHoursDescription: Array<any>;
  protected disabledOrders: boolean;
  protected created: string;

  get Active() {
    return this.active;
  }
  get Id() {
    return this.id;
  }
  get Name() {
    return this.name;
  }
  get Description() {
    return this.description;
  }
  get Logo() {
    return this.logo;
  }
  get Phone() {
    return this.phone;
  }
  get Email() {
    return this.email;
  }
  get BusinessGroupId() {
    return this.businessGroupId;
  }
  get BusinessGroupName() {
    return this.businessGroupName;
  }
  get AreaId() {
    return this.areaId;
  }
  get AreaName() {
    return this.areaName;
  }
  get Address1() {
    return this.address1;
  }
  get Address2() {
    return this.address2;
  }
  get Address3() {
    return this.address3;
  }
  get City() {
    return this.city;
  }
  get Postcode() {
    return this.postcode;
  }
  get Latitude() {
    return this.latitude;
  }
  get Longitude() {
    return this.longitude;
  }
  get Categories(){
    return this.categories;
  }
  get FoodsContractNumber() {
    return this.foodsContractNumber;
  }
  get FoodsMonthlyFee() {
    return this.foodsMonthlyFee;
  }
  get FoodsCommissionPercent() {
    return this.foodsCommissionPercent;
  }
  get Shipping() {
    return this.shipping;
  }
  get DeliveryRate() {
    return this.deliveryRate;
  }
  get MinOrderValue() {
    return this.minOrderValue;
  }
  get Created() {
    return this.created;
  }

  get DeliveryTime() {
    return this.deliveryTime;
  }

  get OpeningHoursDescription() {
    return this.openingHoursDescription;
  }

  get DisabledOrders() {
    return this.disabledOrders;
  }

  get Tags() {
    var tags = Array();
    if (this.Categories) {
      this.Categories.forEach((v: any,_) => {
        tags.push(v.category_name);
      })
    }

    return tags;
  }
}
export  class Restaurant extends IRestaurant {

  constructor(builder: RestaurantBuilder) {
    super();
    this.active = builder.Active;
    this.id = builder.Id;
    this.name = builder.Name;
    this.description = builder.Description ;
    this.logo = builder.Logo ;
    this.phone = builder.Phone ;
    this.email = builder.Email ;
    this.businessGroupId = builder.BusinessGroupId;
    this.businessGroupName = builder.BusinessGroupName;
    this.areaId = builder.AreaId;
    this.areaName = builder.AreaName;
    this.address1 = builder.Address1;
    this.address2 = builder.Address2;
    this.address3 = builder.Address3;
    this.city = builder.City;
    this.postcode = builder.Postcode;
    this.latitude = builder.Latitude;
    this.longitude = builder.Longitude;
    this.categories = builder.Categories;
    this.foodsContractNumber = builder.FoodsContractNumber;
    this.foodsMonthlyFee = builder.FoodsMonthlyFee;
    this.foodsCommissionPercent = builder.FoodsCommissionPercent;
    this.shipping = builder.Shipping;
    this.deliveryRate = builder.DeliveryRate;
    this.minOrderValue = builder.MinOrderValue;
    this.created = builder.Created;
    this.deliveryTime = builder.DeliveryTime;
    this.openingHoursDescription = builder.OpeningHoursDescription;
    this.disabledOrders = builder.DisabledOrders;
  }

  setActive(active: boolean) {
    this.active = active;
    return this;
  }
  setId(id: string) {
    this.id = id;
    return this;
  }
  setName(name: string) {
    this.name = name;
    return this;
  }
  setDescription(description: string) {
    this.description = description;
    return this;
  }
  setLogo(logo: string) {
    this.logo = logo;
    return this;
  }
  setPhone(phone: string) {
    this.phone = phone;
    return this;
  }
  setEmail(email: string) {
    this.email = email;
    return this;
  }
  setBusinessGroupId(businessGroupId: string) {
    this.businessGroupId = businessGroupId;
    return this;
  }
  setBusinessGroupName(name: string) {
    this.businessGroupName = name;
    return this;
  }
  setAreaId(areaId: string) {
    this.areaId = areaId;
    return this;
  }
  setAreaName(name: string) {
    this.areaName = name;
    return this;
  }
  setAddress1(address1: string) {
    this.address1 = address1;
    return this;
  }
  setAddress2(address2: string) {
    this.address2 = address2;
    return this;
  }
  setAddress3(address3: string) {
    this.address3 = address3;
    return this;
  }
  setCity(city: string) {
    this.city = city;
    return this;
  }
  setPostcode(postcode: string) {
    this.postcode = postcode;
    return this;
  }
  setLatitude(latitude: string) {
    this.latitude = latitude;
    return this;
  }
  setLongitude(longitude: string) {
    this.longitude = longitude;
    return this;
  }
  setCategories(categories: object[]){
    this.categories = categories;
    return this;
  }
  setFoodsConractNumber(foodsContractNumber: string) {
    this.foodsContractNumber = foodsContractNumber;
    return this;
  }
  setFoodsMonthlyFee(foodsMonthlyFee: number) {
    this.foodsMonthlyFee = foodsMonthlyFee;
    return this;
  }
  setFoodsCommissionPercent(foodsCommissionPercent: number) {
    this.foodsCommissionPercent = foodsCommissionPercent;
    return this;
  }
  setShipping(shipping: number) {
    this.shipping = this.shipping
    return this;
  }
  setDeliveryRate(deliveryRate: number) {
    this.deliveryRate = deliveryRate;
    return this;
  }
  setMinOrderValue(minOrderValue: number) {
    this.minOrderValue = minOrderValue;
    return this;
  }
  setCreated(created: string) {
    this.created = created;
    return this;
  }

  setOpeningHoursDescription(hours: Array<any>) {
    this.openingHoursDescription = hours;
    return this;
  }

  toJSON(): Object {
    return {
      active: this.Active,
      id: this.Id,
      name: this.Name,
      description: this.Description,
      logo: this.Logo,

      email: this.Email,
      // business_group_id: this.BusinessGroupId,
      // business_group_name: this.BusinessGroupName,
      address: {
        // area_name: this.AreaName,
        area_id: this.AreaId,
        address1: this.Address1,
        address2: this.Address2,
        address3: this.Address3,
        city: this.City,
        postcode: this.Postcode,
        phone: this.Phone,
      },
      latitude: this.Latitude,
      longitude: this.Longitude,
      categories: this.Categories,
      foodsContractNumber: this.FoodsContractNumber,
      foodsCommissionPercent: this.FoodsCommissionPercent,
      shipping: this.Shipping,
      deliveryRate: this.DeliveryRate,
      minOrderValue: this.MinOrderValue,
      delivery_time: this.DeliveryTime,
      opening_hours_description: this.OpeningHoursDescription,
      created: this.Created,
    };
  }
}

export class RestaurantBuilder extends IRestaurant{
  constructor(name: string) {
    super();
    this.name = name;
  }


  setActive(active: boolean) {
    this.active = active;
    return this;
  }
  setId(id: string) {
    this.id = id;
    return this;
  }
  setName(name: string) {
    this.name = name;
    return this;
  }
  setDescription(description: string) {
    this.description = description;
    return this;
  }
  setLogo(logo: string) {
    this.logo = logo;
    return this;
  }
  setPhone(phone: string) {
    this.phone = phone;
    return this;
  }
  setEmail(email: string) {
    this.email = email;
    return this;
  }
  setBusinessGroupId(businessGroupId: string) {
    this.businessGroupId = businessGroupId;
    return this;
  }
  setBusinessGroupName(name: string) {
    this.businessGroupName = name;
    return this;
  }
  setAreaId(areaId: string) {
    this.areaId = areaId;
    return this;
  }
  setAreaName(name: string) {
    this.areaName = name;
    return this;
  }
  setAddress1(address1: string) {
    this.address1 = address1;
    return this;
  }
  setAddress2(address2: string) {
    this.address2 = address2;
    return this;
  }
  setAddress3(address3: string) {
    this.address3 = address3;
    return this;
  }
  setCity(city: string) {
    this.city = city;
    return this;
  }
  setPostcode(postcode: string) {
    this.postcode = postcode;
    return this;
  }
  setLatitude(latitude: string) {
    this.latitude = latitude;
    return this;
  }
  setLongitude(longitude: string) {
    this.longitude = longitude;
    return this;
  }
  setCategories(categories: object[]){
    this.categories = categories;
    return this;
  }
  setFoodsConractNumber(foodsContractNumber: string) {
    this.foodsContractNumber = foodsContractNumber;
    return this;
  }
  setFoodsMonthlyFee(foodsMonthlyFee: number) {
    this.foodsMonthlyFee = foodsMonthlyFee;
    return this;
  }
  setFoodsCommissionPercent(foodsCommissionPercent: number) {
    this.foodsCommissionPercent = foodsCommissionPercent;
    return this;
  }
  setShipping(shipping: number) {
    this.shipping = shipping;
    return this;
  }
  setDeliveryRate(deliveryRate: number) {
    this.deliveryRate = deliveryRate;
    return this;
  }
  setMinOrderValue(minOrderValue: number) {
    this.minOrderValue = minOrderValue;
    return this;
  }
  setCreated(created: string) {
    this.created = created;
    return this;
  }

  setDeliveryTime(deliveryTime: string) {
    this.deliveryTime = deliveryTime;
    return this;
  }

  setOpeningHoursDescription(hours: Array<any>) {
    this.openingHoursDescription = hours;
    return this;
  }

  setDisabledOrders(disabled: boolean) {
    this.disabledOrders = disabled;
    return this;
  }

  build(): Restaurant {
    return new Restaurant(this);
  }
}
