import { DataService } from './../services/data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DialogService } from '../services/dialog.service';
import { GenericModalComponent } from '../components/generic-modal/generic-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  recoveryGroup: FormGroup;

  constructor(
    private data: DataService,
    private dialog: DialogService,
    private router: Router,
  ) {
    this.recoveryGroup = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
      }
    );
  }

  ngOnInit(): void {

  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.data.recoverPassword(this.email?.value).subscribe(
      {
        next: (resp: any) => {
          console.log(resp);
          if (resp.status == 1) {
            this.dialog.dialog.open(GenericModalComponent, {data:
              {message: "Jeśli podany e-mail znajduje się w systemie, dostaniesz wiadomość z instrukcją.", title: "Odzyskiwania hasła"}
            }).afterClosed().subscribe(res => {
              this.router.navigate(["/login"]);
            })

          } else {
            this.dialog.OpenErrorDialog({message: resp.message}).afterOpened().subscribe(res => {
              console.log(res);
              this.router.navigate(["/login"]);
            })
          }


        },
        error: err => {
          console.log(err);
          const notification = document.getElementById('error-notification');
          notification?.classList.remove('is-hidden');
        }
      }
    )
  }

  get email() { return this.recoveryGroup.get('email'); }

}
