export class IIngredientGroupItem{
    protected id: string;
    protected name: string;
    protected price: number;
    protected selected: boolean;
    protected business_group_id: string;
    protected restaurant_id: string;
    protected ingredient_group_id: string;

    get Id() {
        return this.id;
    }
    get Name() {
        return this.name;
    }
    get Price(){
        return this.price;
    }
    get Selected(){
        return this.selected;
    }
    get BusinessGroupId(){
        return this.business_group_id;
    }
    get RestaurantId() {
        return this.restaurant_id;
    }
    get IngredientGroupId() {
        return this.ingredient_group_id;
    }
}
export class IngredientGroupItem extends IIngredientGroupItem {
    constructor(builder: IngredientGroupItemBuilder){
        super();
        this.id = builder.Id;
        this.name = builder.Name;
        this.price = builder.Price;
        this.selected = builder.Selected;
        this.business_group_id = builder.BusinessGroupId
        this.restaurant_id = builder.RestaurantId
        this.ingredient_group_id = builder.IngredientGroupId
    }
    setId(id: string) {
        this.id = id;
        return this;
    }
    setName(name: string) {        
        this.name = name;
        return this;
    }
    setPrice(price: number) {
        this.price = price;
        return this;
    }
    setSelected(selected: boolean) {
        this.selected = selected;
        return this
    }
    setBusinessGroupId(business_group_id: string){
        this.business_group_id = business_group_id;
        return this;
    }
    setRestaurantId(restaurant_id: string){
        this.restaurant_id = restaurant_id;
        return this;
    }
    setIngredientGroupId(ingredient_group_id: string){
        this.ingredient_group_id = ingredient_group_id;
        return this;
    }
    toJSON(): Object{
        return{
            id: this.Id,
            name: this.Name,
            price: this.Price,
            selected: this.Selected,

        };
    }  
}

export class IngredientGroupItemBuilder extends IIngredientGroupItem{
    constructor(name: string){
        super();
        this.name = name;
    }

    setId(id: string) {
        this.id = id;
        return this;
    }
    setName(name: string) {        
        this.name = name;
        return this;
    }
    setPrice(price: number) {
        this.price = price;
        return this;
    }
    setSelected(selected: boolean) {
        this.selected = selected;
        return this
    }
    setBusinessGroupId(business_group_id: string){
        this.business_group_id = business_group_id;
        return this;
    }
    setRestaurantId(restaurant_id: string){
        this.restaurant_id = restaurant_id;
        return this;
    }
    setIngredientGroupId(restaurant_id: string){
        this.restaurant_id = restaurant_id;
        return this;
    }
    
    build(): IngredientGroupItem {
        return new IngredientGroupItem(this);
    }
}
