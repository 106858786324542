import { Restaurant } from 'src/app/models/restaurant.model';
import { OrderBuilder } from './../models/order.model';
import { DataService } from 'src/app/services/data.service';
import { Account, AccountBuilder } from 'src/app/models/account.model';
import { StateService } from 'src/app/services/state.service';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Cart, CartBuilder, CartItem} from "../models/cart.model";
import {Observable, of} from "rxjs";
import { Address, AddressBuilder } from "src/app/models/address.model";
import { Order } from "src/app/models/order.model";
import { DialogService } from '../services/dialog.service';
import { PaymentType } from '../models/payment.model';
import { Router } from '@angular/router';
import { RestaurantBuilder } from '../models/restaurant.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {

  PaymentType = PaymentType;

  cart: Cart = new CartBuilder().build();
  order: Order = <Order>{};
  restaurant: Restaurant = <Restaurant>{};
  $cartItems: Observable<CartItem[]> = of();
  account: Account = new AccountBuilder().build();
  address: Address = new AddressBuilder().build();
  loading = false;

  isDelivery = true;
  isAddressFormOpened = false;
  isCustomAddress = false;

  paymentFormGroup = new FormGroup({
    payment_type: new FormControl("2", [
      Validators.required
    ]),
  })

  deliveryFormGroup = new FormGroup({
    delivery_type: new FormControl("1", [
      Validators.required
    ]),
  })

  constructor(
    private state: StateService,
    private api: DataService,
    private dialog: DialogService,
    private router: Router,
  ) {
    let json = localStorage.getItem("cart") || null;
    this.cart = new CartBuilder().load(json);
    this.order = new OrderBuilder().load(localStorage.getItem("order") || null);
    if (this.cart !== null) {
      this.order.setCart(this.cart);
    }

    this.calculateDeliveryFee();
    this.calculatePaymentFee();
    this.paymentFormGroup.get("payment_type")?.setValue(this.order.PaymentType.toString());
    this.deliveryFormGroup.get("delivery_type")?.setValue(this.order.DeliveryType.toString());
    if (this.order.Address.Address1 !== '') {
      this.isCustomAddress = true;
      this.address = this.order.Address;
    }

    this.$cartItems = new Observable<CartItem[]>(observer => {
      observer.next(this.cart.CartItems);
    });

  }

  addressFormGroup = new FormGroup({
    // area_id: new FormControl(this.address.area_id),
    // area_name: new FormControl(this.address.area_name),
    address1: new FormControl("", [
      Validators.required,
    ]),
    address2: new FormControl("", [
      Validators.required
    ]),
    address3: new FormControl("", []),
    city: new FormControl("", [
      Validators.required
    ]),
    postcode: new FormControl("",
      [
        Validators.required,
        Validators.pattern("^\\d{5}$")
      ]
    ),
    phone: new FormControl("",
      [
        Validators.required
      ]
    )
  })




  ngOnInit(): void {
    this.getAccount();
    // this.getRestaurant();


    if (this.isCustomAddress) {
      this.address1?.setValue(this.order.Address.Address1)
      this.address2?.setValue(this.order.Address.Address2)
      this.address3?.setValue(this.order.Address.Address3)
      this.city?.setValue(this.order.Address.City)
      this.postcode?.setValue(this.order.Address.Postcode.replace('-', ''))
      this.phone?.setValue(this.order.Address.Phone)
    }

  }

  openForm() {
    this.isAddressFormOpened = true;
  }

  closeForm() {
    this.address = new AddressBuilder()
      .setAddress1(this.address1?.value)
      .setAddress2(this.address2?.value)
      .setAddress3(this.address3?.value)
      .setCity(this.city?.value)
      .setPostcode(this.postcode?.value.slice(0, 2) + "-" + this.postcode?.value.slice(2, 5))
      .setPhone(this.phone?.value)
      .build();

    this.isAddressFormOpened = false;
    this.order = new OrderBuilder()
      .setAddress(this.address)
      .setCart(this.cart)
      .setPayment(this.order.PaymentType)
      .setDelivery(this.order.DeliveryType)
      .build();
    localStorage.setItem("order", JSON.stringify(this.order.toJSON()));
  }

  cancelForm() {
    this.isAddressFormOpened = false;
  }

  get address1() {
    return this.addressFormGroup.get('address1');
  }

  get address2() {
    return this.addressFormGroup.get('address2');
  }

  get address3() {
    return this.addressFormGroup.get('address3');
  }

  get city() {
    return this.addressFormGroup.get('city');
  }

  get postcode() {
    return this.addressFormGroup.get('postcode');
  }

  get phone() {
    return this.addressFormGroup.get('phone');
  }

  getAccount() {
    if (this.isCustomAddress)
      return

    this.api.getAccount().subscribe({
      next: (account: Account) => {
        this.account = account;
        this.state.sendData(this.account);
        this.address = new AddressBuilder()
          .setAddress1(this.account.Address1)
          .setAddress2(this.account.Address2)
          .setAddress3(this.account.Address3)
          .setCity(this.account.City)
          .setPostcode(this.account.Postcode)
          .setPhone(this.account.Phone)
          .build();

        // console.log(account);
      },
      error: (err: Error) => {
        console.log(err);
        // TODO: display error
      },
      complete: () => {
        // TODO: close loader
      }
    })
  }


  createOrder() {
    let order = {
      cart: this.cart.toJSON(),
      address: this.address.toJSON(),
      note: '',
      payment_type: parseInt(this.paymentFormGroup.get("payment_type")?.value),
      delivery_type: parseInt(this.deliveryFormGroup.get("delivery_type")?.value),
    };

    this.loading = true;

    this.api.createOrder(order).subscribe({
      next: (data: any) => {

        if (data.status === 0) {
          this.loading = false;
          this.dialog.OpenErrorDialog({message: data.message});
        }

        if (order.payment_type === PaymentType.STRIPE_ONLINE_GLOBAL_PAYMENT) {
          if (data.status === 1 && data.url) {
            this.clearCart();
            window.location.href = data.url;
          }

        } else if (order.payment_type === PaymentType.CASH_PAYMENT) {
            if (data.status === 1) {
              this.clearCart();
              this.router.navigate(["/account"]);
            }



        } else if (order.payment_type === PaymentType.HOTPAY_ONLINE_GLOBAL_PAYMENT) {
          console.log("HOTPAY_ONLINE_GLOBAL_PAYMENT", data);
          console.log("HOTPAY_ONLINE_GLOBAL_PAYMENT", (data.status === 1 && data.hash && data.secret && data.total && data.description && data.www));
          if (data.status === 1 && data.url) {
            this.clearCart();
            window.location.href = data.url;
          }
        }




      },
      error: (err: HttpErrorResponse) => {
        // TODO
        console.log(err);
        this.loading = false;
        this.dialog.OpenErrorDialog({message: err.error?.message || err.message});
      },
      complete: () => {
        this.loading = false;
      }
    })

  }

  getRestaurant() {
    this.api.getRestaurant(new RestaurantBuilder("").setId(this.cart.RestaurantId).build()).subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => {

      }
    })
  }

  calculatePaymentFee() {
    console.log("payment_type=", this.order.PaymentType);
    switch(this.order.PaymentType) {
      case PaymentType.STRIPE_ONLINE_GLOBAL_PAYMENT:
        let fee = (1.00 * 10 + (this.cart.TotalPrice * 0.04) * 10 + (this.order.DeliveryFee * 0.04) * 10) / 10;
        this.order.setPaymentFee(fee); break;
      case PaymentType.CASH_PAYMENT:
        this.order.setPaymentFee(0.00); break;
      case PaymentType.HOTPAY_ONLINE_GLOBAL_PAYMENT:
        this.order.setPaymentFee(0.00); break;
      }


  }

  calculateDeliveryFee() {
    switch (this.order.DeliveryType) {
      case 1: // delivery
        this.order.setDeliveryFee(this.cart.DeliveryRate);
        break;
      case 2: // collection
        this.order.setDeliveryFee(0);
        break;
      }
  }

  setPaymentType(e: any) {
    console.log("event=", e);
    let paymentType = e.target.value
    this.paymentFormGroup.get("payment_type")?.setValue(paymentType);
    this.order.setPayment(parseInt(paymentType));
    this.calculatePaymentFee();

    localStorage.setItem("order", JSON.stringify(this.order.toJSON()));

  }

  setDeliveryType(e: any) {
    console.log("cart", this.cart);
    let deliveryType = e.target.value
    let deliveryRate = 0;
    this.deliveryFormGroup.get("delivery_type")?.setValue(deliveryType);
    this.order.setDelivery(parseInt(deliveryType));

    this.calculateDeliveryFee();
    this.calculatePaymentFee();

    localStorage.setItem("cart", JSON.stringify(this.cart.toJSON()));

    localStorage.setItem("order", JSON.stringify(this.order.toJSON()));
  }

  clearCart() {
    localStorage.setItem("cart", "");
    localStorage.setItem("order", "");

    let cart = new CartBuilder().load(null);
    this.state.updateCart(cart);
    this.state.updateCartItems(cart.CartItems);
  }
}

