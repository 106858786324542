import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

@Injectable()
export class LogoutGuard implements CanActivate {

  constructor(
    private router: Router,

    ) {}

  canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot):  boolean {
      // return true if you want to navigate, otherwise return false
      localStorage.setItem("token", "");
      this.router.navigate(["/"]);
      return true;
  }

}
