import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.css'],
})
export class ProductModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  productQuantity = 1;
  basePrice = this.data.form.get('price').value;
  price = this.basePrice;

  ngOnInit(): void {
    this.updatePrice();

    this.data.form.valueChanges.subscribe((v: any) => {
      v.ingredient_groups.forEach((group: any) => {
        if (group.choice_type == 'singleSelection') {
          group.ingredients.forEach((ingredient: any) => {
            ingredient.selected = false;
            if (group.value == ingredient.id) {
              ingredient.selected = true;
            }
          });
        }
      });

      this.updatePrice();
    });
  }

  onSend(form: NgForm) {
    if (form.status === 'INVALID') {
    } else {
      // TODO:
      this.dialogRef.close();
    }
  }

  getKeys(dict: object) {
    return Object.keys(dict);
  }

  increaseQuantity() {
    this.productQuantity++;
    this.data.form.get('quantity').value++;
    this.updatePrice();
  }
  decreaseQuantity() {
    if (this.productQuantity > 1) {
      this.productQuantity--;
      this.data.form.get('quantity').value--;
    }
    this.updatePrice();
  }

  updatePrice() {
    let total = this.data.form.get('price').value * 1.0;
    this.data.form
      .get('ingredient_groups')
      .controls.forEach((ingredientGroupFormGroup: any) => {
        // console.log(ingredientGroupFormGroup);
        if (
          ingredientGroupFormGroup.get('choice_type').value ===
          'multipleSelection'
        ) {
          let selected = false;
          ingredientGroupFormGroup
            .get('ingredients')
            .controls.forEach((ingredientFormGroup: any) => {
              // console.log(ingredientFormGroup);
              if (ingredientFormGroup.get('selected').value === true) {
                if (
                  ingredientGroupFormGroup.get('price').value > 0 &&
                  selected === false
                ) {
                  total += ingredientGroupFormGroup.get('price').value;
                  selected = true;
                } else {
                  total += ingredientFormGroup.get('price').value;
                }
              }
            });
        }

        if (
          ingredientGroupFormGroup.get('choice_type').value ===
          'singleSelection'
        ) {
          let value = ingredientGroupFormGroup.get('value').value;
          ingredientGroupFormGroup
            .get('ingredients')
            .controls.forEach((ingredientFormGroup: any) => {
              // console.log(ingredientFormGroup);
              if (ingredientFormGroup.get('id').value === value) {
                total += ingredientFormGroup.get('price').value;
              }
            });
        }
      });

    this.price = total * this.data.form.get('quantity').value * 1.0;
  }
}
