<div class="content" fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start center">
  <h2 class="mt-2">Twoje zamówienie</h2>
  <ng-container *ngIf="($cartItems | async)?.length!==0; else empty;">
    <div class="box">
      <h2>Koszyk</h2>
      <div class="basket_row" fxLayout="row" fxLayoutAlign="flex-start center"
           *ngFor="let cartItem of ($cartItems | async); let i=index">
        <div class="icon_div has-text-grey pl-0" fxLayout="row">
          <div class="btn" (click)="changeQuantity(cartItem, -1)">&#8722;</div>
          <span class="ml-2 mr-2">{{ cartItem.Quantity }}</span>
          <div class="btn" (click)="changeQuantity(cartItem, 1)">&#43;</div>
        </div>
        <div>
          <p class="has-text-weight-medium mb-0">{{ cartItem.Name }}</p>
          <div class="is-size-7">
            <p class="m-0 has-text-grey" *ngFor="let ingredient of cartItem.IngredientNames">{{ ingredient }}</p>
          </div>

        </div>
        <div class="change_section">
          {{ cartItem.TotalPrice | number: '1.2-2' }} zł
        </div>
      </div>
      <hr>
      <section class="total_section">
        <strong>Razem</strong>
        <span class="has-text-weight-medium">
            {{ ($cart|async)?.TotalPrice | number: '1.2-2' }} zł
          </span>
      </section>

    </div>
    <a routerLink="/summary" class="button is-success submit">Przejdź do podsumowania</a>
  </ng-container>
</div>

<ng-template #empty>

  Twój koszyk jest pusty


</ng-template>
