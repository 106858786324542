<main class="panel" fxFlex>
  <p class="panel-heading is-pink">
    {{ title }}
  </p>

  <div *ngIf="!orders || orders.length === 0" class="order-item p-3">
    Brak zamówień
  </div>

  <table class="table">
    <tbody>
    <tr *ngFor="let order of orders; let i=index">
      <ng-container *ngIf="(limit !== undefined && limit > i) || limit === undefined">
        <td class="order-id"><strong class="has-text-weight-medium">{{ order?.Id }}</strong></td>
        <td><p>{{ order!!.Created * 1000 | date:'dd-MM-y HH:mm:ss' }}</p></td>
        <td><p class="status has-text-success has-text-weight-medium">{{ Status.getStatusName(order.Status) }}</p></td>
        <td><p>{{ order?.Total | number: '1.2-2' }} zł</p></td>
        <td class="pr-3 pl-3"><i class="fas fa-angle-right"></i></td>
      </ng-container>
    </tr>
    </tbody>
  </table>


</main>
