<div class="panel">
  <p class="panel-heading mb-0 is-pink">
    Zmień hasło
  </p>
  <form class="p-5" #form="ngForm" (ngSubmit)="changePassword(password.value)">
    <div class="field">
      <label class="label">Nowe hasło</label>
      <div class="control">
        <input class="input" [ngModel]="password.value" #password="ngModel"  type="password" name="password" placeholder="Wpisz hasło" required minlength="8">
        <p class="help is-danger" *ngIf="password.errors?.minlength && password.touched">
          Hasło musi się składać z minimum 8 znaków
       </p>
      </div>
    </div>
    <div class="field">
      <label class="label">Potwierdź nowe hasło</label>
      <div class="control">
        <input class="input" [ngModel]="password2.value" #password2="ngModel" type="password" name="password_confirm" placeholder="Potwierdź hasło" required minlength="8">
        <p class="help is-danger" *ngIf="password2.errors?.minlength && password2.touched">
          Hasło musi się składać z minimum 8 znaków
       </p>
        <p class="help is-danger" *ngIf="form.valid && password.value !== password2.value">
          Podane hasła nie są identyczne
       </p>
      </div>
    </div>

    <button [disabled]="form.invalid || password.value !== password2.value || loading" [class.spinner]="loading" class="button is-primary">Zapisz</button>
  </form>
</div>

