import { RestaurantBuilder } from './../models/restaurant.model';
import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { ProductGroup } from '../models/product_group.model';
import { Product } from '../models/product.model';
import { ProductGroupBuilder } from '../models/product_group.model';
import { ActivatedRoute } from '@angular/router';
import { take, finalize } from 'rxjs/operators';
import { Restaurant } from '../models/restaurant.model';

@Component({
  selector: 'app-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.css']
})
export class ProductGroupComponent implements OnInit {

  products: Product[] = [];
  productGroupId: string = '';
  restaurantId: string = '';
  restaurant: Restaurant;
  areaId: string = '';
  productGroup: ProductGroup;

  productGroups: ProductGroup[] = [];

  constructor(
    private api: DataService,
    private route: ActivatedRoute,
  ) {
    this.route.parent?.params.pipe(
      take(1),
    ).subscribe((param) => {
      this.areaId = param.areaId;
      this.restaurantId = param.restaurantId;

    });
    this.route.params.pipe(
      take(1),
    ).subscribe((param) => {

      this.productGroupId = param.productGroupId;

    });

    this.productGroup = new ProductGroupBuilder('')
        .setRestaurantId(this.restaurantId)
        .setId(this.productGroupId)
        .build();

      this.restaurant = new RestaurantBuilder('')
        .setId(this.restaurantId)
        .setAreaId(this.areaId)
        .build();
  }

  ngOnInit(): void {
    console.log("ProductGroupComponent");
    this.getProductGroups();
    // this.getRestaurant();
    // this.getProducts();
  }


  getProducts() {
    this.api.getProducts(this.productGroup).subscribe({
      next: (products: Product[]) => {
        this.products = products;
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => {

      }
    })
  }

  getRestaurant() {
    this.api.getRestaurant(this.restaurant).subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
        console.log(restaurant);
      },
      error: (err: Error) => {
        console.log(err);
      },
      complete: () => {

      }
    })
  }

    // menu
    getProductGroups() {
      this.api.getProductGroups(this.restaurant).subscribe({
        next: (productGroups: ProductGroup[]) => {
          this.productGroups = productGroups;
        },
        error: (err: Error) => {
          console.log(err);
        },
        complete: () => {

        }
      })
    }
}
