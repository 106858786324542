import { Component, OnInit } from '@angular/core';

interface FooterLink {
  text: string;
  url: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  FOOTER_LINKS: FooterLink[] = [
    {
      text: 'Kontakt',
      url: '/contact',
    },
    {
      text: 'Regulamin',
      url: '/terms',
    },
    {
      text: 'Polityka prywatności',
      url: '/privacy-policy',
    },
  ];

  constructor() { }

  ngOnInit(): void { }
}
