

<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">


  <form class="box" (ngSubmit)="onSubmit($event);" [formGroup]="recoveryGroup">
    <div class="field">
      <label class="label">Email</label>
      <div class="control has-icons-right">
        <input class="input" type="email" formControlName="email" placeholder="twoj@email.com" >
      </div>
      <p *ngIf="email?.errors?.required && (recoveryGroup.touched || recoveryGroup.dirty)" class="help is-danger">Wprowadź e-mail</p>
    </div>

    <button fxFill [disabled]="recoveryGroup.invalid" class="button is-primary">Przypomnij</button>


  </form>



</div>



