<main class="main">
  <article>
    <h1 class="title has-text-weight-semibold">Polityka prywatności</h1>
    <div class="content p-5">
      <section class="content_section">
        <p class="mb-0">
          <a
            href="https://docs.speedyjedzonko.pl/Polityka_Prywatnosci_i_cookies.pdf"
            >Polityka prywatności</a
          >
        </p>
      </section>
    </div>
  </article>
</main>
