<section class="restaurants">
  <section class="hero">
    <div class="hero-body pl-0">
      <p class="title">
        Restauracje <span class="tag is-primary">{{ area_name }}</span> <!--TODO update area name -->
      </p>
      <p class="subtitle is-size-6">
        <a [routerLink]="['/areas']">
          <i class="fas fa-angle-left mr-2"></i>
          Zobacz inne obszary
        </a>

      </p>
    </div>
  </section>


  <div class="content" fxFlexAlign="center center">
    <div fxLayout="row wrap" fxLayout.xs="column">
      <div *ngFor="let restaurant of restaurants" fxLayout="row wrap" class="restaurant-tile">
        <app-tile [area]="area" [restaurant]="restaurant"></app-tile>
      </div>
    </div>
    <div *ngIf="isEmpty" class="empty">
      <i class="fas fa-utensils"></i>
      <h1 class="m-1">Dla wybranego obszaru nie ma żadnych restauracji.</h1>
      <a [routerLink]="['/areas/']">Zobacz inne obszary</a>
    </div>
  </div>

</section>
