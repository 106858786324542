import { DataService } from 'src/app/services/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  loginGroup: FormGroup

  constructor(
    private router: Router,
    private api: DataService,
  ) {
    this.loginGroup = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        repeat_password: new FormControl('', [Validators.required]),

        address1: new FormControl('', [Validators.required]),
        address2: new FormControl('', [Validators.required]),
        address3: new FormControl('', []),
        city: new FormControl('', [Validators.required]),
        postcode: new FormControl('', [Validators.required]),
      }
    );
  }

  ngOnInit(): void {

  }


  onSubmit() {
    let fields = this.loginGroup.getRawValue();
    this.api.register(fields).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data?.status === 1) {
          this.router.navigateByUrl('login');
        }
      },
      err: (err: Error) => {
        console.log(err);
      },
      complete: () => {

      }
    });



  }


  get phone() { return this.loginGroup.get('phone'); }
  get email() { return this.loginGroup.get('email'); }
  get password() { return this.loginGroup.get('password'); }
  get repeat_password() { return this.loginGroup.get('repeat_password'); }
  get name() { return this.loginGroup.get('name'); }
  get last_name() { return this.loginGroup.get('last_name'); }

  get address1() { return this.loginGroup.get('address1'); }
  get address2() { return this.loginGroup.get('address2'); }
  get address3() { return this.loginGroup.get('address3'); }
  get city() { return this.loginGroup.get('city'); }
  get postcode() { return this.loginGroup.get('postcode'); }
}
