

<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">


  <form class="box" [formGroup]="recoveryGroup">
    <div class="field">
      <label class="label">Nowe hasło</label>
      <div class="control has-icons-right">
        <input class="input" type="password" formControlName="password" >
      </div>
      <p *ngIf="password?.errors?.required && (recoveryGroup.touched || recoveryGroup.dirty)" class="help is-danger">Wprowadź hasło</p>
    </div>

    <button fxFill [disabled]="recoveryGroup.invalid" class="button is-primary" (click)="onSubmit($event)">Zmień</button>


  </form>



</div>



