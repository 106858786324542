import { CartBuilder } from 'src/app/models/cart.model';
import { Cart } from "./cart.model";
import { Address, AddressBuilder } from "./address.model";

export class IOrder {
  protected id: string = '';
  protected restaurantId: string = '';
  protected businessGroupId: string = '';
  protected cart: Cart = new CartBuilder().build();
  protected created: number = 0;
  protected updated: number = 0;
  protected status: number = 0;
  protected address: Address = new AddressBuilder().build();
  protected note: string = '';
  protected payment_type: number = 0;
  protected delivery_type: number = 0;
  protected delivery_fee: number = 0;
  protected payment_fee: number= 0;

  get Id() {
    return this.id;
  }

  get RestaurantId() {
    return this.restaurantId;
  }

  get BusinessGroupId() {
    return this.businessGroupId;
  }

  get Cart() {
    return this.cart;
  }

  get Created() {
    return this.created;
  }

  get Updated() {
    return this.updated;
  }

  get Status() {
    return this.status;
  }

  get Address() {
    return this.address;
  }

  get Note() {
    return this.note;
  }

  get PaymentType() {
    return this.payment_type;
  }

  get DeliveryType() {
    return this.delivery_type;
  }

  get DeliveryFee() {
    return this.delivery_fee;
  }

  get PaymentFee() {
    return this.payment_fee;
  }

  get Total() {
    return this.Cart.TotalPrice + this.DeliveryFee + this.PaymentFee;
  }
}

export class Order extends IOrder {

  constructor(builder: OrderBuilder) {
    super();
    this.id = builder.Id;
    this.businessGroupId = builder.BusinessGroupId;
    this.restaurantId = builder.RestaurantId;
    this.cart = builder.Cart;
    this.created = builder.Created;
    this.updated = builder.Updated;
    this.status = builder.Status;
    this.address = builder.Address;
    this.payment_type = builder.PaymentType;
    this.delivery_type = builder.DeliveryType;
    this.payment_fee = builder.PaymentFee;
    this.delivery_fee = builder.DeliveryFee;

  }

  setAddress(address: Address) {
    this.address = address;
    return this;
  }

  setPayment(paymentType: number) {
    this.payment_type = paymentType;
    return this;
  }

  setPaymentFee(fee: number) {
    this.payment_fee = Math.round((fee + Number.EPSILON) * 100) / 100;;
    return this;
  }

  setDelivery(deliveryType: number) {
    this.delivery_type = deliveryType;
    return this;
  }

  setDeliveryFee(fee: number) {
    this.delivery_fee = fee;
    return this;
  }

  setCart(cart: Cart) {
    this.cart = cart;
    return this;
  }

  toJSON(): Object {
    return {
      "address": this.Address.toJSON(),
      "cart": this.Cart.toJSON(),
      "note": this.Note,
      "payment_type": this.PaymentType,
      "delivery_type": this.DeliveryType,
      "delivery_fee": this.DeliveryFee,
      "payment_fee": this.PaymentFee,
    }
  }

}

export class OrderBuilder extends IOrder {

  constructor() {
    super();
  }

  setId(id: string) {
    this.id = id;
    return this;
  }

  setRestaurantId(restaurantId: string) {
    this.restaurantId = restaurantId;
    return this;
  }

  setBusinessGroupId(businessGroupId: string) {
    this.businessGroupId = businessGroupId;
    return this;
  }

  setCart(cart: Cart) {
    this.cart = cart;
    return this;
  }

  setCreated(created: number) {
    this.created = created;
    return this;
  }

  setUpdated(updated: number) {
    this.updated = updated;
    return this;
  }

  setAddress(address: Address) {
    this.address = address;
    return this;
  }

  setNote(note: string) {
    this.note = note;
    return this;
  }

  setPayment(paymentType: number) {
    this.payment_type = paymentType;
    return this;
  }

  setPaymentFee(fee: number) {
    this.payment_fee = fee;
    return this;
  }

  setDelivery(deliveryType: number) {
    this.delivery_type = deliveryType;
    return this;
  }

  setDeliveryFee(fee: number) {
    this.delivery_fee = fee;
    return this;
  }

  setStatus(status: number) {
    this.status = status;
    return this;
  }

  build() {
    return new Order(this);
  }


  load(json: string|null): Order {
    let _default = `
    {
      "address": ${JSON.stringify(new AddressBuilder().build().toJSON())},
      "cart": null,
      "note": "",
      "payment_type": 2,
      "delivery_type": 1,
      "payment_fee": 0,
      "delivery_fee": 0

    }`;

    console.log("json1", json);
    console.log("json", _default);

    let jOrder: any = '';

    try {
      jOrder = JSON.parse(json || _default);
    } catch (e) {
      console.log(e);
      jOrder = JSON.parse(_default);
    }

    return new OrderBuilder()
      .setPayment(jOrder.payment_type)
      .setDelivery(jOrder.delivery_type)
      .setDeliveryFee(jOrder.delivery_fee)
      .setPaymentFee(jOrder.payment_fee)
      .setAddress(
        new AddressBuilder()
          .setAddress1(jOrder.address.address1)
          .setAddress2(jOrder.address.address2)
          .setAddress3(jOrder.address.address3)
          .setCity(jOrder.address.city)
          .setPostcode(jOrder.address.postcode)
          .setPhone(jOrder.address.phone)
          .build()
      )
      .build();
  }



}
